import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Content,
  DateRangePicker,
  Dropdown,
  FlexboxGrid,
  Header,
  Message,
  Pagination,
  Table,
  toaster,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import { getContentOrders, getPostOrders } from "../services/DashboardService";
import UserEditModal from "../components/UserEditModal";
import { BooleanCell, DateCell } from "../components/CustomCells";

import OrderUpdateModal from "../components/orderUpdateModal";
import ContentOrderUploadDrawer from "../components/ContentOrderUploadDrawer";
import ViewUserDataDrawer from "../components/ViewUserDataDrawer";
import OrderDetailsDrawer from "../components/OrderDetailsDrawer";

const ContentOrders = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("");
  const [data, setData] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  useEffect(() => {
    (async () => {
      const response = await getContentOrders(
        startDate,
        endDate,
        type,
        pageNumber,
        pageSize
      );
      console.log(response.data);
      if (response.status === 200) {
        setData(response?.data?.responseList);
        setPageNumber(response?.data?.pageNumber);
        setTotalPages(response?.data?.totalPages);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response.response?.data?.message || "No Message Available"}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
    })();
  }, [startDate, endDate, type, pageNumber, updateTrigger]);

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} style={{ padding: "4px" }}>
        <ViewUserDataDrawer data={rowData} />
        <OrderUpdateModal
          data={rowData}
          id={rowData[dataKey]}
          type="content"
          updateTrigger={updateTrigger}
          setUpdateTrigger={setUpdateTrigger}
        />
        {rowData.status === "PROGRESS" && (
          <ContentOrderUploadDrawer
            data={rowData}
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        )}
        <OrderDetailsDrawer data={rowData} />
      </Table.Cell>
    );
  };

  return (
    <Container>
      <Header>
        <h2 className="ms-2">CONTENT ORDERS</h2>
      </Header>
      <Content className="">
        <FlexboxGrid justify="between" className="mx-3">
          <div className="me-4">
            <p>Select Type</p>
            <Dropdown
              className="dropdown-width"
              title={type === "" ? "Select Type" : type}
              onSelect={(e) => setType(e)}
            >
              <Dropdown.Item eventKey="">Select</Dropdown.Item>
              <Dropdown.Item eventKey="CANCELLED">CANCELLED</Dropdown.Item>
              <Dropdown.Item eventKey="COMPLETED">COMPLETED</Dropdown.Item>
              <Dropdown.Item eventKey="DELIVERED">DELIVERED</Dropdown.Item>
              <Dropdown.Item eventKey="ORDER_PLACED">
                ORDER_PLACED
              </Dropdown.Item>
              <Dropdown.Item eventKey="PROGRESS">PROGRESS</Dropdown.Item>
              <Dropdown.Item eventKey="PENDING">PENDING</Dropdown.Item>
              <Dropdown.Item eventKey="REJECTED">REJECTED</Dropdown.Item>
              <Dropdown.Item eventKey="REVISION">REVISION</Dropdown.Item>
            </Dropdown>
          </div>
          <div>
            <p>Select Date Interval</p>
            <DateRangePicker
              onChange={(e) => {
                if (e === null) {
                  setStartDate("");
                  setEndDate("");
                } else {
                  setStartDate(
                    `${e[0].getDate()}-${
                      e[0].getMonth() + 1
                    }-${e[0].getFullYear()}`
                  );
                  setEndDate(
                    `${e[1].getDate()}-${
                      e[1].getMonth() + 1
                    }-${e[1].getFullYear()}`
                  );
                }
              }}
              format={"dd-MM-yyyy"}
            />
          </div>
        </FlexboxGrid>
        <div>
          <Table height={window.innerHeight - 200} data={data}>
            <Table.Column width={80}>
              <Table.HeaderCell>Order Id</Table.HeaderCell>
              <Table.Cell dataKey="orderId" />
            </Table.Column>
            <Table.Column width={150}>
              <Table.HeaderCell>Date </Table.HeaderCell>
              <DateCell dataKey="createdDate" />
            </Table.Column>
            <Table.Column width={120}>
              <Table.HeaderCell>word count</Table.HeaderCell>
              <Table.Cell dataKey="wordCount" />
            </Table.Column>
            <Table.Column width={180}>
              <Table.HeaderCell>Article Type</Table.HeaderCell>
              <Table.Cell dataKey="articleType" />
            </Table.Column>
            <Table.Column width={130}>
              <Table.HeaderCell>Content Fee</Table.HeaderCell>
              <Table.Cell dataKey="contentFee" />
            </Table.Column>
            <Table.Column width={180}>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="status" />
            </Table.Column>
            <Table.Column minWidth={100} flexGrow>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="orderId" />
            </Table.Column>
          </Table>
          <Pagination
            layout={["pager"]}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalPages}
            limit={1}
            maxButtons={13}
            activePage={pageNumber + 1}
            onChangePage={async (e) => {
              await setPageNumber(e - 1);
              setUpdateTrigger(!updateTrigger);
            }}
          />
        </div>
      </Content>
    </Container>
  );
};

export default ContentOrders;
