import React, { useEffect, useState } from "react";
import {
  Container,
  Content,
  DateRangePicker,
  Dropdown,
  FlexboxGrid,
  Header,
  Message,
  Table,
  toaster,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import StatusBadge from "../components/Statusbadge";
import { getHomepageContentOrders } from "../services/DashboardService";
import { AmountCell } from "../components/CustomCells";

const DashboardContentOrder = () => {
  const [data, setData] = useState({
    completedOrderAmount: 0,
    pendingOrderAmount: 0,
    rejectedOrderAmount: 0,
    cancelledOrderAmount: 0,
    contentOrderDashboardDtoList: [],
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("");
  useEffect(() => {
    (async () => {
      const response = await getHomepageContentOrders(startDate, endDate, type);
      if (response.status === 200) {
        setData(response.data);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response.response?.data?.message || "No Message Available"}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
    })();
  }, [startDate, endDate, type]);

  return (
    <Container>
      <Header>
        <h2 className="ms-2">Dashboard-home</h2>
      </Header>
      <Content>
        <FlexboxGrid justify="between" className="mx-3">
          <div className="me-4">
            <p>Select Type</p>

            <Dropdown
              className="dropdown-width"
              title={type === "" ? "Select" : type}
              onSelect={(e) => setType(e)}
            >
              <Dropdown.Item eventKey="">Select</Dropdown.Item>
              <Dropdown.Item eventKey="CANCELLED">CANCELLED</Dropdown.Item>
              <Dropdown.Item eventKey="COMPLETED">COMPLETED</Dropdown.Item>
              <Dropdown.Item eventKey="CONTENT_PENDING">
                CONTENT_PENDING
              </Dropdown.Item>
              <Dropdown.Item eventKey="DELIVERED">DELIVERED</Dropdown.Item>
              <Dropdown.Item eventKey="ORDER_PLACED">
                ORDER_PLACED
              </Dropdown.Item>
              <Dropdown.Item eventKey="PROGRESS">PROGRESS</Dropdown.Item>
              <Dropdown.Item eventKey="PENDING">PENDING</Dropdown.Item>
              <Dropdown.Item eventKey="REJECTED">REJECTED</Dropdown.Item>
              <Dropdown.Item eventKey="REVISION">REVISION</Dropdown.Item>
            </Dropdown>
          </div>
          <div>
            <p>Select Date Interval</p>
            <div className="d-flex align-items-center">
              <DateRangePicker
                cleanable={true}
                onClean={() => console.log("cleaned")}
                onChange={(e) => {
                  if (e === null) {
                    setStartDate("");
                    setEndDate("");
                  } else {
                    setStartDate(
                      `${e[0].getDate()}-${
                        e[0].getMonth() + 1
                      }-${e[0].getFullYear()}`
                    );
                    setEndDate(
                      `${e[1].getDate()}-${
                        e[1].getMonth() + 1
                      }-${e[1].getFullYear()}`
                    );
                  }
                }}
                format={"dd-MM-yyyy"}
              />
            </div>
          </div>
        </FlexboxGrid>
        <div
          className=" mt-3 d-flex justify-content-between "
          style={{ gap: "12px" }}
        >
          <div className="flex-grow-1">
            <StatusBadge
              title="Completed Orders"
              amount={data.completedOrderAmount}
            />
          </div>
          <div className="flex-grow-1">
            <StatusBadge
              title="Pending Orders"
              amount={data.pendingOrderAmount}
            />
          </div>
          <div className="flex-grow-1">
            <StatusBadge
              title="Rejected Orders"
              amount={data.rejectedOrderAmount}
            />
          </div>
          <div className="flex-grow-1">
            <StatusBadge
              title="Cancelled Orders"
              amount={data.cancelledOrderAmount}
            />
          </div>
          <div className="flex-grow-1">
            <StatusBadge
              title="Total Revenue"
              amount={
                parseInt(data.pendingOrderAmount) +
                parseInt(data.completedOrderAmount)
              }
            />
          </div>
        </div>
        <div>
          <Table
            className="mt-4"
            height={400}
            data={data.contentOrderDashboardDtoList}
            onRowClick={(data) => {
              console.log(data);
            }}
          >
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="status" />
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>Count</Table.HeaderCell>
              <Table.Cell dataKey="count" />
            </Table.Column>

            <Table.Column flexGrow={1} fixed>
              <Table.HeaderCell>Amount(is $}</Table.HeaderCell>
              <AmountCell dataKey="amount" prefix={"$"} />
            </Table.Column>
          </Table>
        </div>
      </Content>
    </Container>
  );
};

export default DashboardContentOrder;
