import { SET_USER_PROFILE_DATA, UPDATE_THEME } from "./ActionTypes";

const initialState = {
  theme: "dark",
  profileDetails: "",
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_USER_PROFILE_DATA:
      return {
        ...state,
        profileDetails: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
