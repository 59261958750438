import React, { useEffect } from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  FlexboxGrid,
  Footer,
  Form,
  Header,
  Message,
  Panel,
  Schema,
  toaster,
} from "rsuite";
import NavBar from "../components/Navbar";
import TextInput from "../components/TextInput";
import { useSearchParams } from "react-router-dom";
import {
  loginWithEmail,
  setPassword,
  verifyToken,
} from "../services/UserService";
import { saveJWTToken } from "../utils/auth";
import { setUserProfileData } from "../redux/ActionCreator";
import { useDispatch } from "react-redux";

const FinishSetup = () => {
  const [formValue, setFormValue] = React.useState({
    password: "",
    verifyPassword: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    password: Schema.Types.StringType().isRequired("This field is required."),
    verifyPassword: Schema.Types.StringType()
      .addRule((value, data) => {
        console.log(data);

        if (value !== data.password) {
          return false;
        }

        return true;
      }, "The two passwords do not match")
      .isRequired("This field is required."),
  });

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const handleSubmit = () => {
    formRef.current.check()
      ? handlePasswordSetup()
      : toaster.push(
          <Message showIcon type="error" header="Error">
            Please Validate all Fields
          </Message>,
          {
            placement: "topEnd",
          }
        );
  };

  const handlePasswordSetup = async () => {
    const response = await setPassword({
      password: formValue.password,
      preference: "ADVERTISER",
      verificationToken: searchParams.get("verificationToken"),
    });
    if (response.status !== 200) {
      toaster.push(
        <Message showIcon type="error" header="Error">
          {response.response?.data?.message || "No Message Available"}
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    } else {
      console.log("login success ");
      console.log(response);
      saveJWTToken(response.headers.authorization, response.headers.authorizationexipry);
      dispatch(
        setUserProfileData({
          ...response.data,
          jwtToken: response.headers.authorization,
        })
      );
      // saveJWTToken();
      window.location.reload();
    }
  };
  return (
    <Container className="vh-100 d-flex flex-column justify-content-between ">
      <Header>
        <NavBar />
      </Header>
      <Content className=" d-flex flex-column justify-content-center">
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={8}>
            <Panel
              header={<h3>Welcome, {searchParams.get("name")}</h3>}
              bordered
            >
              <h5 className="mb-3">Create your Password</h5>
              <Form
                ref={formRef}
                fluid
                onSubmit={handleSubmit}
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
              >
                <TextInput type="password" label="Password" name={"password"} />
                <TextInput
                  type="password"
                  label="Confirm Password"
                  name={"verifyPassword"}
                />
                <Form.Group>
                  <ButtonToolbar>
                    <Button type="submit" appearance="primary">
                      Submit
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
      <Footer>Footer</Footer>
    </Container>
  );
};

export default FinishSetup;
