import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Content,
  Dropdown,
  Header,
  Message,
  Pagination,
  Table,
  toaster,
} from "rsuite";
import { getPaymentOrderList } from "../services/DashboardService";
import ViewUserDataDrawer from "../components/ViewUserDataDrawer";
import exportFromJSON from "export-from-json";
import dayjs from "dayjs";

const PaymentOrders = () => {
  const [status, setStatus] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      let response = await getPaymentOrderList(pageNumber, status);
      if (response.status === 200) {
        setData(response?.data?.responseList);
        setPageNumber(response?.data?.pageNumber);
        setTotalPages(response?.data?.totalPages);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response.statusText}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
    })();
  }, [status, pageNumber]);
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} style={{ padding: "4px" }}>
        <ViewUserDataDrawer data={rowData} />
      </Table.Cell>
    );
  };
  const ExportToExcel = () => {
    const DateTime = dayjs().format("DD_MM_YYYY_HH:mm");
    const fileName = `PaymentOrderList_${DateTime}`;
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <Container>
      <Header>
        <h2 className="ms-2">PAYMENT ORDERS</h2>
      </Header>
      <Content>
        <div className="m-3 d-flex">
          <div className="me-4">
            <p>Select Status</p>
            <Dropdown
              className="dropdown-width"
              title={status === "" ? "Select" : status}
              onSelect={e => setStatus(e)}
            >
              <Dropdown.Item eventKey="">Select</Dropdown.Item>
              <Dropdown.Item eventKey="CREATED">CREATED</Dropdown.Item>
              <Dropdown.Item eventKey="FAILED">FAILED</Dropdown.Item>
              <Dropdown.Item eventKey="INIT">INIT</Dropdown.Item>
              <Dropdown.Item eventKey="PENDING">PENDING</Dropdown.Item>
              <Dropdown.Item eventKey="SUCCESS">SUCCESS</Dropdown.Item>
            </Dropdown>
          </div>
          <Button appearance="ghost" onClick={ExportToExcel}>
            Download Data (csv)
          </Button>
        </div>
        <Table height={window.innerHeight - 200} data={data}>
          <Table.Column width={150}>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.Cell dataKey="date" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>User Id</Table.HeaderCell>
            <Table.Cell dataKey="userId" />
          </Table.Column>{" "}
          <Table.Column width={150}>
            <Table.HeaderCell>Order Id</Table.HeaderCell>
            <Table.Cell dataKey="orderId" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>paymentType</Table.HeaderCell>
            <Table.Cell dataKey="paymentType" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>currencyType</Table.HeaderCell>
            <Table.Cell dataKey="currencyType" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>amount</Table.HeaderCell>
            <Table.Cell dataKey="amount" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>paymentAmount</Table.HeaderCell>
            <Table.Cell dataKey="paymentAmount" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.Cell dataKey="status" />
          </Table.Column>
          <Table.Column width={250}>
            <Table.HeaderCell>paymentOrderID</Table.HeaderCell>
            <Table.Cell dataKey="paymentOrderId" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Transaction Amount</Table.HeaderCell>
            <Table.Cell dataKey="transactionAmount" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Transaction Charge</Table.HeaderCell>
            <Table.Cell dataKey="transactionCharge" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <ActionCell dataKey="orderId" />
          </Table.Column>
        </Table>
        <Pagination
          layout={["pager"]}
          prev={true}
          next={true}
          first={true}
          last={true}
          ellipsis={true}
          boundaryLinks={true}
          total={totalPages}
          limit={1}
          maxButtons={13}
          activePage={pageNumber + 1}
          onChangePage={e => {
            setPageNumber(e - 1);
          }}
        />
      </Content>
    </Container>
  );
};

export default PaymentOrders;
