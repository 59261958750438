import { UPDATE_THEME, SET_USER_PROFILE_DATA } from "./ActionTypes";

export const updateTheme = (themeName) => {
  return {
    type: UPDATE_THEME,
    payload: themeName,
  };
};

export const setUserProfileData = (profileData) => {
  return {
    type: SET_USER_PROFILE_DATA,
    payload: profileData,
  };
};
