import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FaGem, FaHeart } from "react-icons/fa";
import { MdPayment,MdRequestPage } from "react-icons/md";
import { RouteNames } from "../constants/Routes";
import { FiLogOut } from "react-icons/fi";
import { setUserProfileData } from "../redux/ActionCreator";
import { removeJWTToken } from "../utils/auth";

const VerticalNav = () => {
  const ItemRef = useRef("");
  const navigate = useNavigate();
  const location = useLocation();
  const { profileDetails, theme } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await removeJWTToken();
    await dispatch(setUserProfileData(""));
    window.location.reload();
  };

  useEffect(() => {
    console.log(ItemRef.current.classList);
  }, [ItemRef]);

  return (
    <ProSidebar>
      <div className="p-2">
        <div className="d-flex justify-content-between">
          <h5>{profileDetails.name}</h5>
          <div
            className="d-flex align-items-center c-ptr"
            onClick={handleLogout}
          >
            <FiLogOut size={20} />
            <div>Logout</div>
          </div>
        </div>
        <div>{profileDetails.email}</div>
      </div>
      <Menu iconShape="square">
        <SubMenu title="Dashboard" icon={<FaHeart />} open>
          <MenuItem
            onClick={() => navigate(RouteNames.HOME)}
            active={location.pathname === RouteNames.HOME}
          >
            Post Order
          </MenuItem>
          <MenuItem
            ref={ItemRef}
            onClick={() => {
              navigate(RouteNames.HOME_CONTENT_ORDER);
            }}
            active={location.pathname === RouteNames.HOME_CONTENT_ORDER}
          >
            Content Order
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.USERS)}
          active={location.pathname === RouteNames.USERS}
        >
          Users
        </MenuItem>
        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.RESET_PASSWORD)}
          active={location.pathname === RouteNames.RESET_PASSWORD}
        >
          Forgot Password
        </MenuItem>
        <SubMenu title="Websites" icon={<FaHeart />}>
          <MenuItem
            onClick={() => navigate(RouteNames.DA_PENDING_WEBSITES)}
            active={location.pathname === RouteNames.DA_PENDING_WEBSITES}
          >
            DA PENDING
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.APPROVAL_PENDING_WEBSITES)}
            active={location.pathname === RouteNames.APPROVAL_PENDING_WEBSITES}
          >
            APPROVAL PENDING
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.WEBSITES)}
            active={location.pathname === RouteNames.WEBSITES}
          >
            Listing
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.RECENT_UPDATES)}
            active={location.pathname === RouteNames.RECENT_UPDATES}
          >
            Update Marketplace Records
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.VERIFY_DOMAIN)}
          active={location.pathname === RouteNames.VERIFY_DOMAIN}
        >
          Domain Verification
        </MenuItem>
        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.POST_ORDERS)}
          active={location.pathname === RouteNames.POST_ORDERS}
        >
          Post Orders
        </MenuItem>
        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.CONTENT_ORDERS)}
          active={location.pathname === RouteNames.CONTENT_ORDERS}
        >
          Content Orders
        </MenuItem>
        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.RECENT_PAYOUT)}
          active={location.pathname === RouteNames.RECENT_PAYOUT}
        >
          Last Payout
        </MenuItem>
        <SubMenu title="Bank Payout" icon={<FaHeart />}>
          <MenuItem
            onClick={() => navigate(RouteNames.BANK_PAYOUT_LISTING)}
            active={location.pathname === RouteNames.BANK_PAYOUT_LISTING}
          >
            Payout List
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.BANK_PENDING_LISTING)}
            active={location.pathname === RouteNames.BANK_PENDING_LISTING}
          >
            Pending List
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.BANK_SETTLEMENT)}
            active={location.pathname === RouteNames.BANK_SETTLEMENT}
          >
            Settlements
          </MenuItem>
        </SubMenu>
        <SubMenu title="Paypal Payout" icon={<FaHeart />}>
          <MenuItem
            onClick={() => navigate(RouteNames.PAYPAL_PAYOUT_LIST)}
            active={location.pathname === RouteNames.PAYPAL_PAYOUT_LIST}
          >
            Payout List
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.PAYPAL_INITIATE_PAYOUT)}
            active={location.pathname === RouteNames.PAYPAL_INITIATE_PAYOUT}
          >
            Initiate Payout
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.PAYPAL_PENDING_PAYOUT)}
            active={location.pathname === RouteNames.PAYPAL_PENDING_PAYOUT}
          >
            Pending Payout
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.PAYPAL_SETTLEMENT)}
            active={location.pathname === RouteNames.PAYPAL_SETTLEMENT}
          >
            Settlements
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.PAYMENT_ORDERS)}
          active={location.pathname === RouteNames.PAYMENT_ORDERS}
        >
          Payment Listing
        </MenuItem>

        <MenuItem
          icon={<MdPayment />}
          onClick={() => navigate(RouteNames.PAYOUT_LISTING)}
          active={location.pathname === RouteNames.PAYOUT_LISTING}
        >
          Payout Listing
        </MenuItem>

        <MenuItem
          icon={<MdRequestPage />}
          onClick={() => navigate(RouteNames.PAYPAL_INVOICE_LIST)}
          active={location.pathname === RouteNames.PAYPAL_INVOICE_LIST}
        >
          Paypal Invoices
        </MenuItem>

        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.CONTACT_US)}
          active={location.pathname === RouteNames.CONTACT_US}
        >
          Contact Us
        </MenuItem>
        <SubMenu title="Upload" icon={<FaHeart />}>
          <MenuItem
            onClick={() => navigate(RouteNames.UPLOAD_CONFIG)}
            active={location.pathname === RouteNames.UPLOAD_CONFIG}
          >
            Config
          </MenuItem>
          <MenuItem
            onClick={() => navigate(RouteNames.UPLOAD_IMAGE)}
            active={location.pathname === RouteNames.UPLOAD_IMAGE}
          >
            Images
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={<FaGem />}
          onClick={() => navigate(RouteNames.CACHE_RESET)}
          active={location.pathname === RouteNames.CACHE_RESET}
        >
          Reload Cache
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default VerticalNav;
