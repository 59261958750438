import React from "react";
import { Button, Drawer, List, Modal } from "rsuite";
const ViewUserDataDrawer = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button appearance="link" onClick={handleOpen}>
        View
      </Button>
      <Drawer open={open} onClose={handleClose} size="sm">
        <Drawer.Header>
          <Modal.Title>Details</Modal.Title>
        </Drawer.Header>
        <Drawer.Body>
          {Object.entries(data)?.map(([key, value]) => (
            <div className="d-flex mb-2 ">
              <p style={{ width: 200 }} className="m-0">
                {key}:
              </p>
              <p style={{ width: 300, wordWrap:'break-word' }} className=" m-0 text-wrap">
                {typeof value === "object"
                  ? JSON.stringify(value)?.toString()
                  : value?.toString()}
              </p>
            </div>
          ))}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default ViewUserDataDrawer;
