import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Content,
  Dropdown,
  Header,
  Message,
  Pagination,
  Table,
  toaster,
} from "rsuite";
import {
  getPaymentOrderList,
  getPaypalInvoiceList,
} from "../services/DashboardService";
import ViewUserDataDrawer from "../components/ViewUserDataDrawer";
import exportFromJSON from "export-from-json";
import dayjs from "dayjs";
import paypalInvoiceUpdateModal from "../components/paypalInvoiceUpdateModal";
import PaypalInvoiceUpdateModal from "../components/paypalInvoiceUpdateModal";

const PaypalInvoiceList = () => {
  const [status, setStatus] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    (async () => {
      let response = await getPaypalInvoiceList(pageNumber, status);
      if (response.status === 200) {
        setData(response?.data?.invoiceResponseDtoList);
        setPageNumber(response?.data?.pageNumber);
        setTotalPages(response?.data?.totalPages);
      }
    })();
  }, [status, pageNumber, updateTrigger]);
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} style={{ padding: "4px" }}>
        <ViewUserDataDrawer data={rowData} />
        <PaypalInvoiceUpdateModal
          data={rowData}
          id={rowData[dataKey]}
          type="content"
          updateTrigger={updateTrigger}
          setUpdateTrigger={setUpdateTrigger}
        />
      </Table.Cell>
    );
  };
  const ExportToExcel = () => {
    const DateTime = dayjs().format("DD_MM_YYYY_HH:mm");
    const fileName = `PaypalInvoiceList_${DateTime}`;
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Paypal Invoices</h2>
      </Header>
      <Content>
        <div className="m-3 d-flex">
          <div className="me-4">
            <p>Select Status</p>
            <Dropdown
              className="dropdown-width"
              title={status === "" ? "Select" : status}
              onSelect={(e) => setStatus(e)}
            >
              <Dropdown.Item eventKey="">Select</Dropdown.Item>
              <Dropdown.Item eventKey="CANCELLED">CANCELLED</Dropdown.Item>
              <Dropdown.Item eventKey="DELETED">DELETED</Dropdown.Item>
            </Dropdown>
          </div>
          <Button appearance="ghost" onClick={ExportToExcel}>
            Download Data (csv)
          </Button>
        </div>
        <Table height={window.innerHeight - 200} data={data}>
          <Table.Column width={150}>
            <Table.HeaderCell>Invoice Date</Table.HeaderCell>
            <Table.Cell dataKey="invoiceDate" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Invoice Number</Table.HeaderCell>
            <Table.Cell dataKey="invoiceNumber" />
          </Table.Column>{" "}
          <Table.Column width={250}>
            <Table.HeaderCell>Invoice Url</Table.HeaderCell>
            <Table.Cell dataKey="invoiceUrl" />
          </Table.Column>
          <Table.Column width={250}>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.Cell dataKey="message" />
          </Table.Column>
          <Table.Column width={170}>
            <Table.HeaderCell>Invoice Status</Table.HeaderCell>
            <Table.Cell dataKey="invoiceStatus" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Paypal Invoice Status</Table.HeaderCell>
            <Table.Cell dataKey="paypalInvoiceStatus" />
          </Table.Column>
          <Table.Column minWidth={270} flexGrow>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <ActionCell dataKey="orderId" />
          </Table.Column>
        </Table>
        <Pagination
          layout={["pager"]}
          prev={true}
          next={true}
          first={true}
          last={true}
          ellipsis={true}
          boundaryLinks={true}
          total={totalPages}
          limit={1}
          maxButtons={13}
          activePage={pageNumber + 1}
          onChangePage={(e) => {
            setPageNumber(e - 1);
          }}
        />
      </Content>
    </Container>
  );
};

export default PaypalInvoiceList;
