import React from "react";
import { Button, Drawer, Dropdown, Input, Message, toaster } from "rsuite";
import axios from "axios";
import Endpoints from "../constants/Endpoints";
import { checkOS } from "../services/Helpers";
import { fetchJWTToken } from "../utils/auth";

const ContentOrderUpload = ({
  data,
  update,
  updateTrigger,
  setUpdateTrigger,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdate = async () => {
    const formData = new FormData();
    const token = fetchJWTToken();
    const values = {
      userId: data.userId,
      orderId: data.orderId,
    };
    console.log(values);
    formData.append("documentFile", selectedFile);
    try {
      const response = await axios.post(
        `${
          Endpoints.ORDER_CONTENT_UPLOAD
        }?contentUploadRequest=${encodeURIComponent(JSON.stringify(values))}`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toaster.push(
          <Message showIcon type="success" header="success">
            file Upload Success
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
        setUpdateTrigger(!updateTrigger);
        handleClose();
      }
    } catch (err) {
      toaster.push(
        <Message showIcon type="error" header="error">
          {err?.response?.data?.error || "No Message Available"}
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    }
  };
  return (
    <>
      <Button appearance="link" onClick={handleOpen}>
        UPLOAD
      </Button>
      <Drawer open={open} onClose={handleClose} size="xs">
        <Drawer.Header>
          <Drawer.Title>Edit Website</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div>
            <div className="my-3">
              <input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </div>
            <Button onClick={handleUpdate} appearance="primary">
              UPLOAD
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default ContentOrderUpload;
