import axios from "axios";
import { fetchJWTToken, handle401 } from "../utils/auth";
import Endpoints from "../constants/Endpoints";
import { handleErrorBlock } from "../utils/utils";

const token = fetchJWTToken();

export const getBankPaymentAccountsList = async () => {
  try {
    const res = await axios.get(`${Endpoints.BANK_PAYMENT_ACCOUNTS}`, {
      headers: {
        authorization: token,
      },
    });
    return res;
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getBankPayoutsList = async () => {
  try {
    return await axios.get(
      `${Endpoints.BANK_PAYOUT_LIST}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};
export const getBankPendingList = async () => {
  try {
    return await axios.get(
      `${Endpoints.BANK_PENDING_LIST}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getPayoutListing = async (pageNumber,pageSize,status) => {
  try {
    return await axios.get(
      `${Endpoints.PAYOUT_LISTING}?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};
