import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  Form,
  Header,
  Message,
  Panel,
  Schema,
  toaster,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import TextInput from "../components/TextInput";
import { verifyDomain } from "../services/DashboardService";

const DomainVerification = () => {
  const [formValue, setFormValue] = React.useState({
    domainName: "",
    websiteUrl: "",
    userEmail: "",
  });
  const [formError, setFormError] = React.useState({});
  const [success, setSuccess] = useState(false);
  const [output, setOutput] = useState("");
  const formRef = React.useRef();
  const model = Schema.Model({
    domainName: Schema.Types.StringType().isRequired("This Field is required."),
    websiteUrl: Schema.Types.StringType()
      .isURL("Enter a valid URL")
      .isRequired("This Field is required"),
    userEmail: Schema.Types.StringType().isEmail("Enter a valid email"),
  });
  const handleSubmit = () => {
    formRef.current.check()
      ? handleDomainVerification()
      : toaster.push(
          <Message showIcon type="error" header="Error">
            Please Validate all Fields
          </Message>,
          {
            placement: "topEnd",
          }
        );
  };

  const handleDomainVerification = async () => {
    const response = await verifyDomain(formValue);
    if (response.status === 200) {
      toaster.push(
        <Message showIcon type="success" header="success">
          Domain Verified Successful
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
      setOutput(response.data);
    }
    // else {
    //   toaster.push(
    //     <Message showIcon type="error" header="Error">
    //       {response.response?.data?.message || "No Message Available"}
    //     </Message>,
    //     {
    //       placement: "topEnd",
    //       duration: 2000,
    //     }
    //   );
    // }
  };
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Verify Domain</h2>
      </Header>
      <Content className="d-flex flex-column  align-items-center">
        <Panel bordered className="mt-3 w-50" style={{ maxWidth: 500 }}>
          <h4>Enter Domain Name and URL To Verify</h4>
          <Form
            ref={formRef}
            fluid
            onSubmit={handleSubmit}
            onChange={setFormValue}
            onCheck={setFormError}
            formValue={formValue}
            model={model}
          >
            <TextInput label="Domain Name" name={"domainName"} />
            <TextInput label="Website URL" name={"websiteUrl"} />
            <TextInput label="User Email" name={"userEmail"} />
            <Form.Group>
              <ButtonToolbar>
                <Button disabled={success} type="submit" appearance="primary">
                  Submit
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>
        {output && (
          <Panel bordered className="mt-3" header="Output:">
            {JSON.stringify(output)}
          </Panel>
        )}
      </Content>
    </Container>
  );
};

export default DomainVerification;
