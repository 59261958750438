import React from 'react';
import VerticalNav from "./VerticalNav";
import {Container, Header} from "rsuite";

const DashboardContainer = ({ component }) => {
  return (
    <Container className="vh-100 d-flex flex-row">
      <VerticalNav />
      {component}
    </Container>
  );
};

export default DashboardContainer;
