import React, { useState } from "react";
import {
  Button,
  Drawer,
  Dropdown,
  Input,
  Message,
  Modal,
  toaster,
} from "rsuite";
import {
  updateContentOrder,
  updatePaypalInvoice,
  updatePostOrder,
} from "../services/DashboardService";
import { value } from "firebase-tools/lib/deploymentTool";

const PaypalInvoiceUpdateModal = ({
  id,
  type,
  updateTrigger,
  setUpdateTrigger,
  data,
}) => {
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [status, setStatus] = useState(
    data.invoiceStatus
      ? data.invoiceStatus === "PENDING" || data.invoiceStatus === "FAILED"
        ? data.invoiceStatus
        : ""
      : ""
  );
  const [paypalInvoiceStatus, setPaypalInvoiceStatus] = useState(
    data.paypalInvoiceStatus
      ? data.paypalInvoiceStatus === "CANCELLED" ||
        data.paypalInvoiceStatus === "DELETED"
        ? data.paypalInvoiceStatus
        : ""
      : ""
  );
  const [reason, setReason] = useState(data.reason);

  const handleUpdate = async () => {
    if (status.length === 0) {
      toaster.push(
        <Message showIcon type="error" header="Error">
          Select a Valid Status
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    } else {
      await updatePaypalInvoice(
        data.invoiceNumber,
        paypalInvoiceStatus.length === 0
          ? data.paypalInvoiceStatus
          : paypalInvoiceStatus,
        status
      )
        .then((res) => {
          if (res.status === 200) {
            toaster.push(
              <Message showIcon type="success" header="success">
                Updated successfully!
              </Message>,
              {
                placement: "topEnd",
                duration: 2000,
              }
            );
            setUpdateTrigger(!updateTrigger);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (updateType === "") {

    // } else {
    //   let response =
    //     type === "post"
    //       ? await updatePostOrder(id, updateType, reason)
    //       : await updateContentOrder(id, updateType, reason);
    //   if (response.status === 200) {

    //   }
    //   // else {
    //   //   console.log(response, "res", response.data);
    //   //   toaster.push(
    //   //     <Message showIcon type="error" header="Error">
    //   //       {response?.response?.data.message || "No Message Available"}
    //   //     </Message>,
    //   //     {
    //   //       placement: "topEnd",
    //   //       duration: 3000,
    //   //     }
    //   //   );
    //   // }
    // }
  };
  return (
    <>
      <Button appearance="link" onClick={() => setOpenUpdateModal(true)}>
        UPDATE
      </Button>
      <Drawer
        open={openUpdateModal}
        onClose={() => {
          setOpenUpdateModal(false);
          setReason("");
        }}
      >
        <Drawer.Header>
          <Modal.Title>UPDATE</Modal.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p>Invoice Number: {data.invoiceNumber}</p>
            <p>Invoice Data: {data.invoiceDate}</p>
            <p>Invoice Status: {data.invoiceStatus}</p>
            <p>Paypal Invoice Status: {data.paypalInvoiceStatus}</p>

            <p>Select Status</p>

            <Dropdown
              className="dropdown-width mb-4 "
              title={status.length > 0 ? status : "Select"}
              onSelect={(e) => {
                setStatus(e);
                // setOpenUpdateModal(true);
              }}
            >
              <>
                <Dropdown.Item eventKey={""}>Select</Dropdown.Item>
                <Dropdown.Item eventKey={"PENDING"}>PENDING</Dropdown.Item>
                <Dropdown.Item eventKey={"FAILED"}>FAILED</Dropdown.Item>
              </>
            </Dropdown>
            {status === "FAILED" ? (
              <>
                <p>Select Paypal Invoice Status</p>

                <Dropdown
                  className="dropdown-width mb-4 "
                  title={
                    paypalInvoiceStatus.length > 0
                      ? paypalInvoiceStatus
                      : "Select"
                  }
                  onSelect={(e) => {
                    setPaypalInvoiceStatus(e);
                  }}
                >
                  <Dropdown.Item eventKey={""}>Select</Dropdown.Item>
                  <Dropdown.Item eventKey={"CANCELLED"}>
                    CANCELLED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"DELETED"}>DELETED</Dropdown.Item>
                </Dropdown>
              </>
            ) : null}

            <Button
              className="mt-3"
              onClick={handleUpdate}
              appearance="primary"
            >
              UPDATE
            </Button>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default PaypalInvoiceUpdateModal;
