import React, { useState } from "react";
import {
  Button,
  Container,
  Content,
  Dropdown,
  Header,
  Input,
  Message,
  toaster,
  Uploader,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import { fetchJWTToken } from "../utils/auth";
import axios from "axios";
import Endpoints from "../constants/Endpoints";
const styles = {
  lineHeight: "200px",
};
const UploadImage = () => {
  const token = fetchJWTToken();
  const [selectedFile, setSelectedFile] = useState("");
  const [output, setOutput] = useState([]);
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    let res = await axios.post(
      "https://qaapi.outreachmantra.com/om/admin/upload/images",
      formData,
      { headers: { authorization: token } }
    );
    console.log(res);
  };

  return (
    <Container>
      <Header>
        <h2 className="ms-2">Image Uploader</h2>
      </Header>
      <Content className=" mt-4 d-flex flex-column  align-items-center">
        <h4 className="mb-4">Select image below </h4>
        <Uploader
          style={styles}
          accept="image/*"
          action={Endpoints.UPLOAD_IMAGE}
          name={"image"}
          onSuccess={(e) => setOutput([...output, e])}
          onError={(e) =>
            toaster.push(
              <Message showIcon type="error" header="error">
                {e.response.message}
              </Message>,
              {
                placement: "topEnd",
                duration: 5000,
              }
            )
          }
          draggable
          headers={{ authorization: token }}
        >
          <div>
            <p className="py-5">Click or Drag files to this area to upload</p>
            <p>Note: Image will upload automatically when selected!</p>
          </div>
        </Uploader>
        {output.map((item) => (
          <div>
            <p>{item}</p>
          </div>
        ))}
      </Content>
    </Container>
  );
};

export default UploadImage;
