import React, { useState } from "react";
import { Button, Drawer, Dropdown, Message, Modal, toaster } from "rsuite";
import { putUpdateContactTicket } from "../services/DashboardService";

const ContactUsUpdateModal = ({
  id,
  data,
  setUpdateTrigger,
  updateTrigger,
}) => {
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [updateStatus, setUpdateStatus] = useState("");

  const handleUpdate = async () => {
    if (updateStatus === "") {
      toaster.push(
        <Message showIcon type="error" header="Error">
          select valid status
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    } else {
      const response = await putUpdateContactTicket(id, updateStatus);
      if (response.status === 200) {
        toaster.push(
          <Message showIcon type="success" header="Success">
            Status Updated successfully!
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
        setUpdateTrigger(!updateTrigger);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response?.response?.data?.message || "No Message Available"}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
      setOpenUpdateModal(false);
    }
  };

  return (
    <>
      <Button appearance="link" onClick={() => setOpenUpdateModal(true)}>
        UPDATE
      </Button>
      <Drawer
        open={openUpdateModal}
        onClose={() => {
          setUpdateStatus("");
          setOpenUpdateModal(false);
        }}
      >
        <Drawer.Header>
          <Modal.Title>UPDATE</Modal.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p>Name: {data.name}</p>
            <p>Name: {data.email}</p>
            <p>Select Status</p>
            <Dropdown
              className="mb-4 dropdown-width "
              title={updateStatus === "" ? "Select" : updateStatus}
              onSelect={(e) => {
                setUpdateStatus(e);
                setOpenUpdateModal(true);
              }}
              activeKey={updateStatus}
            >
              <Dropdown.Item eventKey={""}>Select</Dropdown.Item>
              <Dropdown.Item eventKey={"ALL"}>ALL</Dropdown.Item>
              <Dropdown.Item eventKey={"CLOSED"}>CLOSED</Dropdown.Item>
              <Dropdown.Item eventKey={"OPEN"}>OPEN</Dropdown.Item>
              <Dropdown.Item eventKey={"PROGRESS"}>PROGRESS</Dropdown.Item>
            </Dropdown>
            <Button onClick={handleUpdate} appearance="primary">
              UPDATE
            </Button>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default ContactUsUpdateModal;
