import React, { useState } from "react";
import {
  Container,
  Content,
  Header,
  Message,
  Table,
  toaster,
  Uploader,
} from "rsuite";
import Endpoints from "../../constants/Endpoints";
import { fetchJWTToken } from "../../utils/auth";
const styles = {
  lineHeight: "200px",
};
const BankSettlement = () => {
  const token = fetchJWTToken();
  const [output, setOutput] = useState();
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Bank Payout Settlement</h2>
      </Header>
      <Content className=" mt-4 d-flex flex-column  align-items-center">
        <h4 className="mb-4">Select Bank Settlement file below</h4>
        <Uploader
          style={styles}
          accept=".xlsx"
          action={Endpoints.BANK_SETTLEMENT}
          name={"updateBankPayoutFile"}
          onSuccess={(e) => {
            toaster.push(
              <Message showIcon type="success" header="Success">
                {e}
              </Message>,
              {
                placement: "topEnd",
                duration: 2000,
              }
            );
            setOutput(e);
          }}
          onError={(e) => {
            console.log(e);
            toaster.push(
              <Message showIcon type="error" header="Error">
                {JSON.stringify(e.response.message)}
              </Message>,
              {
                placement: "topEnd",
                duration: 2000,
              }
            );
          }}
          draggable
          headers={{ authorization: token }}
        >
          <div>
            <p className="py-5">Click or Drag files to this area to upload</p>
            <p>Note: file will upload automatically when selected!</p>
          </div>
        </Uploader>
        {JSON.stringify(output)}
      </Content>
    </Container>
  );
};

export default BankSettlement;
