import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Content,
  Header,
  Message,
  Table,
  toaster,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getInventoryPrefsList,
  getUsersList,
} from "../services/DashboardService";
import { RouteNames } from "../constants/Routes";

const UserSavedFilters = (props) => {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const response = await getInventoryPrefsList(state.userUniqueId);
      console.log(response);
      if (response.status == 200) {
        const newData = response?.data?.responseList;
        setData(newData);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response.response?.data?.message || "No Message Available"}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
    })();
  }, []);

  const EditCell = ({ rowData, dataKey, onChange, ...props }) => {
    return (
      <Table.Cell {...props}>
        <span className="table-content-edit-span">
          {rowData[dataKey] === null ? "N/A" : rowData[dataKey]?.toString()}
        </span>
      </Table.Cell>
    );
  };
  console.log(state, props);
  return (
    <Container>
      <Header className="d-flex justify-content-between">
        <h2 className="m-2">User Saved Preferences</h2>
        <Button
          appearance="link"
          className="me-5"
          onClick={() => {
            navigate(RouteNames.USERS);
          }}
        >
          &larr; Go Back
        </Button>
      </Header>
      <Content>
        <Table height={window.innerHeight - 120} data={data}>
          <Table.Column width={220}>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.Cell dataKey="id" />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell dataKey="name" />
          </Table.Column>
          <Table.Column width={100}>
            <Table.HeaderCell>Post Type</Table.HeaderCell>
            <EditCell dataKey="postType" />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Inventory Name</Table.HeaderCell>
            <Table.Cell dataKey="inventoryNameOnUI" />
          </Table.Column>
          <Table.Column width={100}>
            <Table.HeaderCell>GAValidated</Table.HeaderCell>
            <EditCell dataKey="gaValidated" />
          </Table.Column>
          <Table.Column width={100} data={data}>
            <Table.HeaderCell>Default</Table.HeaderCell>
            <EditCell dataKey="defaultPrefs" />
          </Table.Column>
          <Table.Column width={300} data={data}>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.Cell dataKey="category" />
          </Table.Column>
        </Table>
      </Content>
    </Container>
  );
};

export default UserSavedFilters;
