export const RouteNames = {
  LOGIN: "/login",
  REGISTER: "/register",
  FINISH_SETUP: "/finish-setup",
  FORGET_PASSWORD: "/forget-password",
  ERROR_PAGE: "/error-page",
  HOME: "/",
  HOME_CONTENT_ORDER: "/home-content-order",
  USERS: "/users",
  USER_FILTERS: "/user-filters",
  USER_WEBSITES: "/user-websites",
  RESET_PASSWORD: "/reset-password",
  WEBSITES: "/websites",
  DA_PENDING_WEBSITES: "/da-pending-websites",
  APPROVAL_PENDING_WEBSITES: "/approval-pending-websites",
  RECENT_UPDATES: "/recent-updates",
  VERIFY_DOMAIN: "/verify-domain",
  POST_ORDERS: "/post-orders",
  CONTENT_ORDERS: "/content-orders",
  CACHE_RESET: "/cache-reload",
  UPLOAD_IMAGE: "/upload-image",
  UPLOAD_CONFIG: "/upload-config",
  CONTACT_US: "/contact-us",
  PAYPAL_PAYOUT_LIST: "/paypal-payout-list",
  PAYPAL_INITIATE_PAYOUT: "/paypal-initiate-payout",
  PAYPAL_PENDING_PAYOUT: "/paypal-pending-payout",
  PAYPAL_SETTLEMENT: "/paypal-settlements",
  BANK_PAYMENT_ACCOUNTS: "/bank-payout-accounts",
  BANK_PAYOUT_LISTING: "/bank-payout-listing",
  BANK_PENDING_LISTING: "/bank-pending-listing",
  BANK_SETTLEMENT: "/bank-settlements",
  RECENT_PAYOUT: "/recent-payout",
  PAYMENT_ORDERS: "/payment-orders",
  PAYPAL_INVOICE_LIST: "/paypal-invoices",
  PAYOUT_LISTING: "/payout-listing",
};
