import { handle401 } from "./auth";
import { Message, toaster } from "rsuite";
import React from "react";

// export const handleErrorBlock = (error) => {
//   // Status Code 401
//   if (
//     error?.response?.status === 401 ||
//     error?.response?.data?.status === 401
//   ) {
//     handle401();
//   }
//   // Network Error
//   else if (`${error}`.includes("Network Error")) {
//     // toast.error("Network Error");
//     toaster.push(
//       <Message
//         showIcon
//         type="error"
//         header={error?.response?.status || "ERROR"}
//       >
//         {"Something went wrong, please try again"}
//       </Message>,
//       {
//         placement: "topEnd",
//         duration: 2000,
//       }
//     );
//   }
//   // Server-Side Message
//   else if (error?.response?.data?.message) {
//     toaster.push(
//       <Message showIcon type="error" header={error?.response?.status}>
//         {error?.response?.data?.message}
//       </Message>,
//       {
//         placement: "topEnd",
//         duration: 2000,
//       }
//     );
//   }

//   // No Server-Side Message
//   else if (
//     !`${error}`.includes("Network Error") &&
//     !error?.response?.data?.message
//   ) {
//     // toast.error("Something Went Wrong");
//     toaster.push(
//       <Message
//         showIcon
//         type="error"
//         header={error?.response?.status || "ERROR"}
//       >
//         {"Something went wrong, please try again"}
//       </Message>,
//       {
//         placement: "topEnd",
//         duration: 2000,
//       }
//     );
//     console.log("Error Block:", error);
//   }
// };

export const handleErrorBlock = (error) => {
 // Network Error
if (`${error}`.includes("Network Error")) {
  // toast.error("Network Error");
  toaster.push(
    <Message
      showIcon
      type="error"
      header={error?.response?.status || "ERROR"}
    >
      {"Something went wrong, please try again"}
    </Message>,
    {
      placement: "topEnd",
      duration: 2000,
    }
  );
}
  // Status Code 401
  // Clear Cookies -> Send to Home Screen
 if (
    error?.response?.status === 401 ||
    error?.response?.data?.status === 401
  ) {
    handle401();
    toaster.push(
      <Message
        showIcon
        type="error"
        header={error?.response?.status || "ERROR"}
      >
        {"Unauthorized Error"}
      </Message>,
      {
        placement: "topEnd",
        duration: 2000,
      }
    );
  }

  else if (!`${error}`.includes("Network Error") && error?.response?.status !== 401
          && (`${error?.response?.status}`.includes('40') || `${error?.response?.status}`.includes('50'))) {
    
            if (error?.response?.data?.message) {
      
              toaster.push(
                <Message
                  showIcon
                  type="error"
                  header={error?.response?.status || "ERROR"}
                >
                  {error?.response?.data?.message}
                </Message>,
                {
                  placement: "topEnd",
                  duration: 2000,
                }
              );
            } else {
              toaster.push(
                <Message
                  showIcon
                  type="error"
                  header={error?.response?.status || "ERROR"}
                >
                  {"Something went wrong, please try again"}
                </Message>,
                {
                  placement: "topEnd",
                  duration: 2000,
                }
              );
            }
  }
};
