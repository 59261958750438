import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Content,
  Dropdown,
  FlexboxGrid,
  Header,
  Message,
  Pagination,
  Table,
  toaster,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import {
  getContactUsList,
  putCloseAllTickets,
} from "../services/DashboardService";
import ContactUsUpdateModal from "../components/ContactUsUpdateModal";
import ViewUserDataDrawer from "../components/ViewUserDataDrawer";

const ContactUs = () => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("ALL");
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await getContactUsList(pageNumber, pageSize, status);
      console.log(response);
      if (response.status == 200) {
        setData(response?.data?.contactUsDocumentList);
        setPageNumber(response?.data?.pageNumber);
        setTotalPages(response?.data?.totalPages);
      } else {
        toaster.push(
          <Message showIcon type="error" header="Error">
            {response?.response?.data?.message || "No Message Available"}
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
      }
    })();
  }, [status, updateTrigger]);

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} style={{ padding: "4px" }}>
        <ViewUserDataDrawer data={rowData} />
        <ContactUsUpdateModal
          data={rowData}
          id={rowData[dataKey]}
          updateTrigger={updateTrigger}
          setUpdateTrigger={setUpdateTrigger}
        />
      </Table.Cell>
    );
  };

  const handleCloseTickets = async () => {
    const response = await putCloseAllTickets();
    if (response.status === 200) {
      toaster.push(
        <Message showIcon type="success" header="Success">
          All tickets Closed Successfully!
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    }
    // else {
    //   toaster.push(
    //     <Message showIcon type="error" header="Error">
    //       {response?.response?.data?.message || "No Message Available"}
    //     </Message>,
    //     {
    //       placement: "topEnd",
    //       duration: 2000,
    //     }
    //   );
    // }
    setUpdateTrigger(!updateTrigger);
  };

  return (
    <Container>
      <Header>
        <h2 className="ms-2">Contact Us List</h2>
      </Header>
      <Content>
        <FlexboxGrid justify="space-between" className="mx-3 mb-3">
          <Col>
            <p>Select Status</p>
            <Dropdown
              className="dropdown-width"
              title={status === "" ? "Status" : status}
              onSelect={setStatus}
              activeKey={status}
            >
              <Dropdown.Item eventKey={"ALL"}>ALL</Dropdown.Item>
              <Dropdown.Item eventKey={"CLOSED"}>CLOSED</Dropdown.Item>
              <Dropdown.Item eventKey={"OPEN"}>OPEN</Dropdown.Item>
              <Dropdown.Item eventKey={"PROGRESS"}>PROGRESS</Dropdown.Item>
            </Dropdown>
          </Col>
          <Button appearance="ghost" color="green" onClick={handleCloseTickets}>
            CLOSE ALL TICKETS
          </Button>
        </FlexboxGrid>
        <Table height={window.innerHeight - 200} data={data}>
          <Table.Column width={180}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell dataKey="name" />
          </Table.Column>
          <Table.Column width={350}>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.Cell dataKey="email" />
          </Table.Column>
          <Table.Column width={110}>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell dataKey="type" />
          </Table.Column>
          <Table.Column width={120}>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.Cell dataKey="status" />
          </Table.Column>
          <Table.Column flexGrow>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.Cell dataKey="message" />
          </Table.Column>
          <Table.Column flexGrow fixed>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <ActionCell dataKey="id" />
          </Table.Column>
        </Table>
        <div style={{ padding: 20 }}>
          <Pagination
            layout={["pager"]}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalPages}
            limit={1}
            maxButtons={13}
            activePage={pageNumber + 1}
            onChangePage={async (e) => {
              await setPageNumber(e - 1);
              setUpdateTrigger(!updateTrigger);
            }}
          />
        </div>
      </Content>
    </Container>
  );
};
export default ContactUs;
