import React from "react";

const StatusBadge = ({ icon, title, amount }) => {
  return (
    <div className="border border-white rounded d-flex justify-content-between p-2">
      {icon}
      <div>
        <p>{title}</p>
        <h3>${amount}</h3>
      </div>
    </div>
  );
};

export default StatusBadge;
