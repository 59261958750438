import React, { useState } from "react";
import { Container, Content, Header, Message, toaster, Uploader } from "rsuite";
import VerticalNav from "../components/VerticalNav";
import { fetchJWTToken } from "../utils/auth";
import Endpoints from "../constants/Endpoints";
const styles = {
  lineHeight: "200px",
};
const UploadConfig = () => {
  const token = fetchJWTToken();
  const [output, setOutput] = useState([]);

  return (
    <Container>
      <Header>
        <h2 className="ms-2">Config Uploader</h2>
      </Header>
      <Content className=" mt-4 d-flex flex-column  align-items-center">
        <h4 className="mb-4">Select config File Below </h4>
        <Uploader
          style={styles}
          accept=".json"
          action={Endpoints.UPLOAD_CONFIG}
          name={"configFile"}
          onSuccess={(e) => {
            toaster.push(
              <Message showIcon type="success" header="Success">
                {e}
              </Message>,
              {
                placement: "topEnd",
                duration: 2000,
              }
            );
            setOutput([...output, e]);
          }}
          onError={(e) =>
            toaster.push(
              <Message showIcon type="error" header="error">
                {e.response.message}
              </Message>,
              {
                placement: "topEnd",
                duration: 5000,
              }
            )
          }
          draggable
          headers={{ authorization: token }}
        >
          <div>
            <p className="py-5">Click or Drag files to this area to upload</p>
            <p>Note: file will upload automatically when selected!</p>
          </div>
        </Uploader>
        {output ? output : ""}
      </Content>
    </Container>
  );
};

export default UploadConfig;
