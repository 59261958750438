import axios from "axios";
import Endpoints from "../constants/Endpoints";
import { fetchJWTToken, handle401 } from "../utils/auth";
import { handleErrorBlock } from "../utils/utils";

const token = fetchJWTToken();

export const getWebsitesList = async (
  pageNumber,
  pageSize,
  searchText,
  status,
  hasFeaturedWebsite
) => {
  try {
    return await axios.get(
      `${Endpoints.WEBSITES_LIST}?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}&websiteStatus=${status}&hasFeaturedWebsite=${hasFeaturedWebsite}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};


export const featuredWebsite = async (data) => {
  try {
    return await axios.post(
      `${Endpoints.WEBSITES_FEATURED}`,
      data,
      {
        headers: {
          authorization: token,
        },
      }
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getPendingWebsitesList = async (
  pageNumber,
  pageSize,
  hasDaUpdated
) => {
  console.log(pageNumber);
  try {
    return await axios.get(
      `${Endpoints.WEBSITE_PENDING_LIST}?pageNumber=${pageNumber}&pageSize=${pageSize}&hasDAUpdated=${hasDaUpdated}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};
