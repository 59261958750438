import AuthReducer from "./reducer";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, AuthReducer);

const reduxStateSyncConfig = {};

const storeObj = createStore(
  persistedReducer,

  compose(
    applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig)),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

initMessageListener(storeObj);

export const store = storeObj;

export const persistor = persistStore(store);
