import Cookies from "js-cookie";

export const checkOS = () => {
  const os = navigator.userAgent.toLowerCase();
  const isAndroid = os.indexOf("android") > -1;
  const isIos = os.indexOf("ios") > -1;
  // const isWeb = os.indexOf("web") > -1;
  if (isAndroid) return "ANDROID";
  if (isIos) return "IOS";
  else return "WEB";
};

export const setCookies = (key, value, expires) => {
  Cookies.set(key, value, { expires: expires / (60 * 60 * 24 * 1000) });
};

export const removeCookies = (key) => {
  Cookies.remove(key);
};
