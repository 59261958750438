import React, { useState } from "react";
import {
  Button,
  Drawer,
  Dropdown,
  Input,
  Message,
  Modal,
  toaster,
} from "rsuite";
import {
  updateContentOrder,
  updatePostOrder,
} from "../services/DashboardService";
import {value} from "firebase-tools/lib/deploymentTool";

const OrderUpdateModal = ({
  id,
  type,
  updateTrigger,
  setUpdateTrigger,
  data,
}) => {
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [updateType, setUpdateType] = useState("");
  const [reason, setReason] = useState(data.reason);

  const handleUpdate = async () => {
    if (updateType === "") {
      toaster.push(
        <Message showIcon type="error" header="Error">
          Select a Valid Status
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    } else {
      let response =
        type === "post"
          ? await updatePostOrder(id, updateType, reason)
          : await updateContentOrder(id, updateType, reason);
      if (response.status === 200) {
        toaster.push(
          <Message showIcon type="success" header="success">
            Updated successfully!
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
        setUpdateTrigger(!updateTrigger);
      }
      // else {
      //   console.log(response, "res", response.data);
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response?.response?.data.message || "No Message Available"}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 3000,
      //     }
      //   );
      // }
    }
  };
  return (
    <>
      <Button appearance="link" onClick={() => setOpenUpdateModal(true)}>
        UPDATE
      </Button>
      <Drawer
        open={openUpdateModal}
        onClose={() => {
          setOpenUpdateModal(false);
          setUpdateType("");
          setReason("");
        }}
      >
        <Drawer.Header>
          <Modal.Title>UPDATE</Modal.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p>orderId: {data.orderId}</p>
            {type === "post" && <p>Website: {data.websiteUrl}</p>}
            {type === "content" && <p>Article Type: {data.articleType}</p>}
            <p>Current Status: {data.status}</p>
            <p>Select Status</p>
            <Dropdown
              className="dropdown-width mb-4 "
              title={updateType === "" ? "Select" : updateType}
              onSelect={(e) => {
                setUpdateType(e);
                setOpenUpdateModal(true);
              }}
            >
              {type === "post" ? (
                <>
                  <Dropdown.Item eventKey={""}>Select</Dropdown.Item>
                  <Dropdown.Item eventKey={"CANCELLED"}>
                    CANCELLED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"REJECTED"}>
                    REJECTED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"PENDING"} hidden={data.status !== "UNDER_REVIEW"}>
                    PENDING
                  </Dropdown.Item>
                </>
              ) : (
                <>
                  <Dropdown.Item eventKey={""}>Select</Dropdown.Item>
                  <Dropdown.Item eventKey={"ACCEPTED"}>ACCEPTED</Dropdown.Item>
                  <Dropdown.Item eventKey={"CANCELLED"}>
                    CANCELLED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"REJECTED"}>REJECTED</Dropdown.Item>
                  <Dropdown.Item eventKey={"REVISED"}>REVISED</Dropdown.Item>
                </>
              )}
            </Dropdown>

            <div className="w-100">
              <p className="text-start">Reason:</p>
              <Input
                as="textarea"
                rows={3}
                value={reason}
                placeholder="Enter Reason Here"
                onChange={(e) => {
                  console.log(e);
                  setReason(e);
                }}
              />
            </div>
            <Button
              className="mt-3"
              onClick={handleUpdate}
              appearance="primary"
            >
              UPDATE
            </Button>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default OrderUpdateModal;
