import axios from "axios";
import { fetchJWTToken, handle401 } from "../utils/auth";
import Endpoints from "../constants/Endpoints";
import { handleErrorBlock } from "../utils/utils";

const token = fetchJWTToken();

export const getPaypalPayoutList = async () => {
  try {
    return await axios.get(`${Endpoints.PAYPAL_PAYOUT_LIST}`, {
      headers: {
        authorization: token,
      },
    });
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const postPaypalPayoutInitialize = async () => {
  try {
    return await axios.post(
      `${Endpoints.PAYPAL_PAYOUT_INIT}`,
      {},
      {
        headers: {
          authorization: token,
        },
      }
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getPaypalPendingList = async () => {
  try {
    return await axios.get(`${Endpoints.PAYPAL_PAYOUT_PENDING}`, {
      headers: {
        authorization: token,
      },
    });
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};
