import React from "react";
import { Divider, Panel, Button } from "rsuite";
import moment from "moment";
import ViewUserDataDrawer from "./ViewUserDataDrawer";
import WebsiteEditDrawer from "./WebsiteEditDrawer";
import { RouteNames } from "../constants/Routes";
import { useLocation } from "react-router-dom";
import WebsiteStatusUpdateDrawer from "./WebsiteStatusUpdateDrawer";

const WebsiteDataPanel = ({
  item,
  type,
  updateTrigger,
  setUpdateTrigger,
  changeStatus,
  updateFeaturedWebsite,
}) => {
  const location = useLocation();
  console.log(item, "itemdata");
  return (
    <Panel bordered className="listPanel">
      <div className=" row" style={{ padding: 1 }}>
        <div className="col-xl-9 col-md-12">
          <div className="row ">
            <div className="col-sm-3">
              <div>DomainName: {item.domainName}</div>
              <div>URL: {item?.websiteUrl}</div>
            </div>
            <div className="col-sm-4">
              <div>
                <span>DA: {item.DA}</span>
                <Divider vertical />
                <span>DR: {item.DR}</span>
                <Divider vertical />
                <span>TAT(in days): {item.TAT}</span>
                <Divider vertical />
                <span>PA: {item.pa}</span>
              </div>
              <div>
                <span>
                  Traffic ({item?.trafficLastUpdatedTime}):{" "}
                  {item?.monthlyTraffic}{" "}
                </span>
              </div>
            </div>
            <div className="col-sm-5">
              <div>
                <span>
                  Guest Post:{" "}
                  {item?.postTypeAndFeeMap?.GUEST_POST
                    ? "$" + `${item?.postTypeAndFeeMap?.GUEST_POST}`
                    : "--"}
                </span>
                <Divider vertical />
                <span>
                  LINK INS.:{" "}
                  {item?.postTypeAndFeeMap?.LINK_INSERTION
                    ? "$" + `${item?.postTypeAndFeeMap?.LINK_INSERTION}`
                    : "--"}
                </span>
                <Divider vertical />
                <span>
                  Casino Post:{" "}
                  {item?.postTypeAndFeeMap?.CASINO_POST
                    ? "$" + `${item?.postTypeAndFeeMap?.CASINO_POST}`
                    : "--"}
                </span>
              </div>
              <div>
                <span>
                  created: {moment(item?.createdOn).format("MMM Do YY")}
                </span>
                <Divider vertical />
                <span>
                  modified: {moment(item?.modifiedOn).format("MMM Do YY")}
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-sm-3">
              <div>W. Name: {item?.websiteName}</div>
              <div
                className={`fw-bold
                  ${item?.websiteStatus === "ACTIVATED" && "text-success"}
                 ${item?.websiteStatus === "REJECTED" && "text-danger"}
                   ${item?.websiteStatus === "PENDING" && "text-warning"}`}
              >
                status: {item?.websiteStatus}
              </div>
            </div>
            <div className="col-sm-4">
              <div>domain Verified: {item?.domainVerified?.toString()}</div>
              <div>GA Validated: {item?.gaValidated?.toString()}</div>
            </div>
            <div className="col-sm-5">
              <div>Free Access: {item?.freeAccess?.toString()}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              Category :{" "}
              {item?.categories &&
                item.categories.map((item, index) => {
                  return <span>{item}, </span>;
                })}{" "}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-12">
          <ViewUserDataDrawer data={item} />
          {type === "edit" && (
            <WebsiteEditDrawer
              updateTrigger={updateTrigger}
              data={item}
              showAll={location.pathname === RouteNames.WEBSITES}
              update={() => setUpdateTrigger(!updateTrigger)}
            />
          )}
          {changeStatus && (
            <WebsiteStatusUpdateDrawer
              updateTrigger={updateTrigger}
              key={item.id}
              update={() => setUpdateTrigger(!updateTrigger)}
              data={item}
            />
          )}
          {item.hasFeaturedWebsite ? (
            <Button
              appearance="link"
              onClick={() =>
                updateFeaturedWebsite(item.domainName, false, item.id)
              }
            >
              Remove Featured
            </Button>
          ) : (
            <Button
              appearance="link"
              onClick={() =>
                updateFeaturedWebsite(item.domainName, true, item.id)
              }
            >
              Add Featured
            </Button>
          )}
        </div>
      </div>
    </Panel>
  );
};

export default WebsiteDataPanel;
