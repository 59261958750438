import React, { useEffect, useState } from "react";
import {
  Container,
  Content,
  Header,
  Message,
  Pagination,
  toaster,
} from "rsuite";
import { getPendingWebsitesList } from "../services/WebsitesServices";
import WebsiteDataPanel from "../components/WebsiteDataPanel";

const ApprovalPendingWebsites = () => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    const hasDaUpdated = true;
    (async () => {
      const response = await getPendingWebsitesList(
        pageNumber,
        pageSize,
        hasDaUpdated
      );
      console.log(response.data);
      if (response.status === 200) {
        setPageNumber(response?.data?.pageNumber);
        setTotalPages(response?.data?.totalPages);
        const newData = response?.data?.websiteDocumentList;
        setData(newData);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response.response?.data?.message || "No Message Available"}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
    })();
  }, [pageNumber, updateTrigger]);

  return (
    <Container>
      <Header>
        <h2 className="ms-2">Approval Pending Websites </h2>
      </Header>
      <Content>
        <div className="my-2">
          <Pagination
            layout={["pager"]}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalPages}
            limit={1}
            maxButtons={13}
            activePage={pageNumber + 1}
            onChangePage={async (e) => {
              await setPageNumber(e - 1);
            }}
          />
        </div>
        <div>
          {data.map((item) => (
            <WebsiteDataPanel
              item={item}
              type="view"
              changeStatus
              updateTrigger={updateTrigger}
              setUpdateTrigger={setUpdateTrigger}
            />
          ))}
        </div>
        <div>
          <Pagination
            layout={["pager"]}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalPages}
            limit={1}
            maxButtons={13}
            activePage={pageNumber + 1}
            onChangePage={async (e) => {
              await setPageNumber(e - 1);
            }}
          />
        </div>
      </Content>
    </Container>
  );
};

export default ApprovalPendingWebsites;
