import React, { useState } from "react";
import {
  Button,
  Container,
  Content,
  Dropdown,
  Header,
  Message,
  toaster,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import { getCacheReset } from "../services/DashboardService";

const CacheReload = () => {
  const [type, setType] = useState("");
  const handleSubmit = async () => {
    if (type === "") {
      toaster.push(
        <Message showIcon type="error" header="Error">
          Select a Valid Type
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    } else {
      const response = await getCacheReset(type);
      if (response.status == 200) {
        toaster.push(
          <Message showIcon type="success" header="Success">
            Cache Reset Successfully
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
      } else {
        toaster.push(
          <Message showIcon type="error" header="Error">
            {response?.response?.data.message || "No Message Available"}
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
      }
    }
  };

  return (
    <Container>
      <Header>
        <h2 className="ms-2">Reset Cache</h2>
      </Header>
      <Content className="  d-flex flex-column  align-items-center">
        <div className=" d-flex flex-column align-items-center  p-3  mt-3 ">
          <h4>Select the item to reload cache.</h4>
          <div>
            <Dropdown
              title={type === "" ? "Select" : type}
              onSelect={setType}
              className="my-4 dropdown-width"
              activeKey={type}
            >
              {/* TODO: ALL, CONFIG, EMAIL_TEMPLATES, EVENT_MASTER,APPLICATION_PROPERTIES, EXCHANGE_RATE*/}
              <Dropdown.Item eventKey={""}>Select</Dropdown.Item>
              <Dropdown.Item eventKey={"ALL"}>ALL</Dropdown.Item>
              <Dropdown.Item eventKey={"CONFIG"}>CONFIG</Dropdown.Item>
              <Dropdown.Item eventKey={"EVENT_MASTER"}>
                EVENT MASTER
              </Dropdown.Item>
              <Dropdown.Item eventKey={"EXCHANGE_RATE"}>
                EXCHANGE RATE
              </Dropdown.Item>
              <Dropdown.Item eventKey={"EMAIL_TEMPLATES"}>
                EMAIL TEMPLATES
              </Dropdown.Item>
              <Dropdown.Item eventKey={"APPLICATION_PROPERTIES"}>
              APPLICATION PROPERTIES
              </Dropdown.Item>
            </Dropdown>
          </div>
          <Button appearance="ghost" onClick={handleSubmit}>
            SUBMIT
          </Button>
        </div>
      </Content>
    </Container>
  );
};

export default CacheReload;
