import React from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  FlexboxGrid,
  Footer,
  Form,
  Header,
  Message,
  Panel,
  Schema,
  toaster,
} from "rsuite";
import { loginWithEmail } from "../services/UserService";
import NavBar from "../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import { setCookies } from "../services/Helpers";
import { saveJWTToken } from "../utils/auth";
import { setUserProfileData } from "../redux/ActionCreator";
import { useDispatch } from "react-redux";
import { RouteNames } from "../constants/Routes";

const LoginPage = () => {
  const [formValue, setFormValue] = React.useState({
    email: "",
    password: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    email: Schema.Types.StringType()
      .isEmail("Please enter a valid email address.")
      .isRequired("This Field is required."),
    password: Schema.Types.StringType().isRequired("This field is required."),
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    formRef.current.check()
      ? handleLogin()
      : toaster.push(
          <Message showIcon type="error" header="Error">
            Please Validate all Fields
          </Message>,
          {
            placement: "topEnd",
          }
        );
  };

  const handleLogin = async () => {
    const response = await loginWithEmail({
      email: formValue.email,
      password: formValue.password,
    });
    if (response.status !== 200) {
      toaster.push(
        <Message showIcon type="error" header="Error">
          {response.response?.data?.message || "No Message Available"}
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    } else {
      console.log("login success ");
      console.log(response);
      saveJWTToken(response.headers.authorization, response.headers.authorizationexipry);
      dispatch(
        setUserProfileData({
          ...response.data,
          jwtToken: response.headers.authorization,
        })
      );
      // saveJWTToken();
      window.location.reload();
    }
  };
  const navigate = useNavigate();
  return (
    <Container className="vh-100 d-flex flex-column justify-content-between ">
      <Header>
        <NavBar />
      </Header>
      <Content className=" d-flex flex-column justify-content-center">
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={8}>
            <Panel header={<h3>Login</h3>} bordered>
              <Form
                ref={formRef}
                fluid
                onSubmit={handleSubmit}
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
              >
                <TextInput
                  label="Username or email address"
                  type="email"
                  name="email"
                />
                <TextInput label="Password" type="password" name="password" />
                <ButtonToolbar>
                  <Button type="submit" appearance="primary">
                    Sign in
                  </Button>
                  <Button
                    onClick={() =>
                      navigate(RouteNames.FORGET_PASSWORD, { replace: true })
                    }
                    appearance="link"
                  >
                    Forgot password?
                  </Button>
                </ButtonToolbar>
              </Form>
            </Panel>
            <p className="mt-2">
              New Here? <Link to="/register">Register</Link>
            </p>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
      <Footer>Footer</Footer>
    </Container>
  );
};

export default LoginPage;
