import { Table } from "rsuite";
import React from "react";
import moment from "moment";

export const BooleanCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Table.Cell {...props}>
      <span className="table-content-edit-span">
        {rowData[dataKey]?.toString()}
      </span>
    </Table.Cell>
  );
};

export const AmountCell = ({ rowData, dataKey, prefix, ...props }) => {
  return (
    <Table.Cell {...props}>
      <span className="table-content-edit-span">
        {prefix}
        {rowData[dataKey]?.toString()}
      </span>
    </Table.Cell>
  );
};
export const DateCell = ({ rowData, dataKey, time, ...props }) => {
  return (
    <Table.Cell {...props}>
      <span>
        {moment(rowData[dataKey]).format(
          time ? "MMM DD YYYY h:mm A" : "MMM DD YYYY"
        )}{" "}
      </span>
    </Table.Cell>
  );
};
