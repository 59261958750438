import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Content,
  Dropdown,
  FlexboxGrid,
  Header,
  Input,
  InputGroup,
  Message,
  Pagination,
  toaster,
} from "rsuite";
import { useNavigate } from "react-router-dom";
import VerticalNav from "../components/VerticalNav";
import { Icon } from "@rsuite/icons";
import { FiSearch } from "react-icons/fi";
import { featuredWebsite, getWebsitesList } from "../services/WebsitesServices";
import WebsiteDataPanel from "../components/WebsiteDataPanel";

const WebsitesList = () => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [hasFeaturedWebsite, setHasFeaturedWebsite] = useState(false);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchWebsiteList();
  }, [status, updateTrigger]);

  const fetchWebsiteList = async () => {
    const response = await getWebsitesList(
      pageNumber,
      pageSize,
      searchText,
      status,
      hasFeaturedWebsite
    );
    console.log(response.data);
    if (response.status == 200) {
      setPageNumber(response?.data?.pageNumber);
      setTotalPages(response?.data?.totalPages);
      const newData = response?.data?.websiteDocumentList;
      setData(newData);
    } else {
      toaster.push(
        <Message showIcon type="error" header="Error">
          {response.response?.data?.message || "No Message Available"}
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    }
  };

  const updateFeaturedWebsite = async (
    domainName,
    hasFeaturedWebsite,
    websiteId
  ) => {
    const data = {
      domainName,
      hasFeaturedWebsite,
      websiteId,
    };

    const response = await featuredWebsite(data);
    if (response.status === 200) {
      fetchWebsiteList();
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setPageNumber(0);
      setUpdateTrigger(!updateTrigger);
    }
  };

  return (
    <Container>
      <Header>
        <h2 className="ms-2">Websites List</h2>
      </Header>
      <Content>
        <FlexboxGrid
          justify="space-between"
          align="middle"
          className="mx-3 mb-3"
        >
          <div className="d-flex align-items-center">
            <div className="me-4">
              <p>Select Type</p>
              <Dropdown
                className="dropdown-width"
                title={status === "" ? "Select Status" : status}
                onSelect={(e) => setStatus(e)}
              >
                <Dropdown.Item eventKey="">Select</Dropdown.Item>
                <Dropdown.Item eventKey="ACTIVATED">ACTIVATED</Dropdown.Item>
                <Dropdown.Item eventKey="DEACTIVATED">
                  DEACTIVATED
                </Dropdown.Item>
                <Dropdown.Item eventKey="DISABLED">DISABLED</Dropdown.Item>
                <Dropdown.Item eventKey="PENDING">PENDING</Dropdown.Item>
                <Dropdown.Item eventKey="REJECTED">REJECTED</Dropdown.Item>
                <Dropdown.Item eventKey="REMOVED">REMOVED</Dropdown.Item>
              </Dropdown>
            </div>
            <div>
              <p>Search</p>
              <div className="d-flex align-items-center">
                <InputGroup>
                  <Input
                    value={searchText}
                    onKeyDown={_handleKeyDown}
                    onChange={(e) => {
                      console.log(e);
                      setSearchText(e);
                    }}
                  />
                  <InputGroup.Button
                    onClick={() => {
                      setPageNumber(0);
                      setUpdateTrigger(!updateTrigger);
                    }}
                  >
                    <Icon as={FiSearch} />
                  </InputGroup.Button>
                </InputGroup>
                <p
                  className="c-ptr ms-3"
                  onClick={async () => {
                    await setSearchText("");
                    setPageNumber(0);
                    setUpdateTrigger(!updateTrigger);
                  }}
                >
                  CLEAR
                </p>
              </div>
            </div>
            <div className="mt-3">
              <p></p>

              <div
                className={`rs-dropdown-toggle rs-btn rs-btn-default ${hasFeaturedWebsite?"rs-btn-active":''}`}
                style={{ marginLeft: 20, marginRight: 20 }}
                onClick={()=>{
                  setHasFeaturedWebsite(!hasFeaturedWebsite)
                  setPageNumber(0);
                  setUpdateTrigger(!updateTrigger);
                }}
              >
                Featured Website
              </div>
            </div>
          </div>

          <Col>
            <Pagination
              layout={["pager"]}
              prev={true}
              next={true}
              first={true}
              last={true}
              ellipsis={true}
              boundaryLinks={true}
              total={totalPages}
              limit={1}
              maxButtons={10}
              activePage={pageNumber + 1}
              onChangePage={async (e) => {
                await setPageNumber(e - 1);
                setUpdateTrigger(!updateTrigger);
              }}
            />
          </Col>
        </FlexboxGrid>
        <div>
          {data.map((item) => (
            <WebsiteDataPanel
              item={item}
              type="edit"
              changeStatus
              updateTrigger={updateTrigger}
              setUpdateTrigger={setUpdateTrigger}
              updateFeaturedWebsite={updateFeaturedWebsite}
            />
          ))}
        </div>
        <div className="p-3 d-flex justify-content-between align-items-center ">
          <Pagination
            layout={["pager"]}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalPages}
            limit={1}
            maxButtons={13}
            activePage={pageNumber + 1}
            onChangePage={async (e) => {
              await setPageNumber(e - 1);
              setUpdateTrigger(!updateTrigger);
            }}
          />
        </div>
      </Content>
    </Container>
  );
};

export default WebsitesList;
