import React, { useState } from "react";
import VerticalNav from "../components/VerticalNav";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  Form,
  Header,
  Message,
  Panel,
  Schema,
  toaster,
} from "rsuite";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../services/UserService";
import { userPasswordResetRequest } from "../services/DashboardService";

const MyComponent = () => {
  const [formValue, setFormValue] = React.useState({
    email: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    email: Schema.Types.StringType()
      .isEmail("Please enter a valid email address.")
      .isRequired("This Field is required."),
  });
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    formRef.current.check()
      ? sendResetEmail()
      : toaster.push(
          <Message showIcon type="error" header="Error">
            Please Validate all Fields
          </Message>,
          {
            placement: "topEnd",
          }
        );
  };

  const sendResetEmail = async () => {
    const response = await userPasswordResetRequest({ email: formValue.email });
    if (response.status === 200) {
      toaster.push(
        <Message showIcon type="success" header="Error">
          Password Reset Link Sent Successfully!
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
      setSuccess(true);
    }
    // else {
    //   toaster.push(
    //     <Message showIcon type="error" header="Error">
    //       {response.response?.data?.message || "No Message Available"}
    //     </Message>,
    //     {
    //       placement: "topEnd",
    //       duration: 2000,
    //     }
    //   );
    // }
  };

  return (
    <Container>
      <Header>
        <h2 className="ms-2">Reset User Password</h2>
      </Header>
      <Content className="d-flex flex-column  align-items-center">
        <Panel bordered className="mt-3 w-50" style={{ maxWidth: 500 }}>
          <h4>Enter an Email Id to Reset Password </h4>
          <Form
            ref={formRef}
            fluid
            onSubmit={handleSubmit}
            onChange={setFormValue}
            onCheck={setFormError}
            formValue={formValue}
            model={model}
          >
            <TextInput label="Email" name={"email"} />
            <Form.Group>
              <ButtonToolbar>
                <Button disabled={success} type="submit" appearance="primary">
                  Submit
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>
      </Content>
    </Container>
  );
};

export default MyComponent;
