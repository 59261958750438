export const categoriesData = [
  { label: "Agriculture", value: "Agriculture" },
  { label: "Architecture & Building", value: "Architecture & Building" },
  { label: "Arts & Entertainment", value: "Arts & Entertainment" },
  { label: "Automobiles", value: "Automobiles" },
  { label: "Beauty & Fashion", value: "Beauty & Fashion" },
  { label: "Blogging", value: "Blogging" },
  { label: "Business", value: "Business" },
  { label: "Career & Employment", value: "Career & Employment" },
  { label: "Casino & Gambling", value: "Casino & Gambling" },
  { label: "Cryptocurrency", value: "Cryptocurrency" },
  { label: "Ecommerce & Shopping", value: "Ecommerce & Shopping" },
  { label: "Education", value: "Education" },
  { label: "Finance", value: "Finance" },
  { label: "Food & Drink", value: "Food & Drink" },
  { label: "Games", value: "Games" },
  { label: "General", value: "General" },
  { label: "Health & Fitness", value: "Health & Fitness" },
  { label: "Home & Garden", value: "Home & Garden" },
  { label: "Law & Government", value: "Law & Government" },
  { label: "Marketing & Advertising", value: "Marketing & Advertising" },
  { label: "Music", value: "Music" },
  { label: "News & Media", value: "News & Media" },
  { label: "Others", value: "Others" },
  { label: "Parenting", value: "Parenting" },
  { label: "Pets & Animal", value: "Pets & Animal" },
  { label: "Photography", value: "Photography" },
  { label: "Politics", value: "Politics" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Sports", value: "Sports" },
  { label: "Technology", value: "Technology" },
  { label: "Travel", value: "Travel" },
  {
    label: "Website Design & Development",
    value: "Website Design & Development",
  },
];
