import React from "react";
import { Button, Nav, Navbar } from "rsuite";
import { updateTheme } from "../redux/ActionCreator";
import { FiMoon, FiSun } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

const NavBar = () => {
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  return (
    <Navbar>
      <Navbar.Brand href="#">DASHBOARD</Navbar.Brand>
      <Nav>
        <Nav.Item eventKey="1">Home</Nav.Item>
      </Nav>
      <Nav pullRight>
        <Nav.Item>
          <Button
            onClick={() =>
              dispatch(updateTheme(theme === "dark" ? "light" : "dark"))
            }
          >
            {theme === "dark" ? (
              <FiSun color="yellow" size={26} />
            ) : (
              <FiMoon color="black" size={26} />
            )}
          </Button>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

export default NavBar;
