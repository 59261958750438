import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  FlexboxGrid,
  Footer,
  Form,
  Schema,
  Header,
  Panel,
  toaster,
  Message,
} from "rsuite";
import NavBar from "../components/Navbar";
import TextInput from "../components/TextInput";
import { loginWithEmail, registerEmail } from "../services/UserService";
import { Link } from "react-router-dom";

const RegisterPage = () => {
  const [formValue, setFormValue] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    firstName: Schema.Types.StringType().isRequired("This field is required."),
    lastName: Schema.Types.StringType().isRequired("This field is required."),
    email: Schema.Types.StringType()
      .isEmail("Please enter a valid email address.")
      .isRequired("This Field is required."),
  });

  const handleSubmit = () => {
    formRef.current.check()
      ? registerUser()
      : toaster.push(
          <Message showIcon type="error" header="Error">
            Please Validate all Fields
          </Message>,
          {
            placement: "topEnd",
          }
        );
  };

  const registerUser = async () => {
    const response = await registerEmail({
      email: formValue.email,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
    });
    if (response.status !== 200) {
      toaster.push(
        <Message showIcon type="error" header="Error">
          {response.response?.data?.message || "No Message Available"}
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    } else {
      toaster.push(
        <Message showIcon type="success" header="Email Sent">
          Check your inbox for verification email.
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    }
  };

  return (
    <>
      <Container className="vh-100 d-flex flex-column justify-content-between ">
        <Header>
          <NavBar />
        </Header>
        <Content className=" d-flex flex-column justify-content-center">
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={8}>
              <Panel header={<h3>Sign Up</h3>} bordered>
                <Form
                  ref={formRef}
                  fluid
                  onSubmit={handleSubmit}
                  onChange={setFormValue}
                  onCheck={setFormError}
                  formValue={formValue}
                  model={model}
                >
                  <TextInput label="First Name" name={"firstName"} />
                  <TextInput label="Last Name" name={"lastName"} />
                  <TextInput label="Email" name={"email"} />
                  <Form.Group>
                    <Button type="submit" appearance="primary">
                      Register
                    </Button>
                  </Form.Group>
                </Form>
              </Panel>
              <p className="mt-2">
                Already a user? <Link to="/login">Log In</Link>
              </p>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
        <Footer>Footer</Footer>
      </Container>
    </>
  );
};

export default RegisterPage;
