import React, { useEffect } from "react";
import { Button, Drawer, Dropdown, Input, Message, toaster } from "rsuite";
import { updateWebsite } from "../services/DashboardService";
import Select from "react-select";
import { categoriesData } from "../constants/categories";

const WebsiteEditDrawer = ({ data, update, showAll, updateTrigger }) => {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  useEffect(() => {
    setModalData({
      DA: data?.DA,
      DR: data?.DR,
      domainName: data.domainName,
      freeAccess: data.freeAccess,
      monthlyTraffic: data.monthlyTraffic,
      TAT: data.TAT,
      websiteId: data.id,
      categories: data.categories,
      guidelines: data.guidelines,
    });
  }, [update, open, updateTrigger]);
  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   setModalData({ ...data });
  // }, [open]);

  const handleCategoryChange = (e) => {
    setModalData({ ...modalData, categories: e.map(({ label }) => label) });
  };

  const handleUpdate = async () => {
    const response = await updateWebsite(modalData);
    if (response.status == 200) {
      toaster.push(
        <Message showIcon type="success" header="Success">
          Website Edit Successfully
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
      update();
      handleClose();
    }
    // else {
    //   toaster.push(
    //     <Message showIcon type="error" header="Error">
    //       {response?.response?.data?.message || "No Message Available"}
    //     </Message>,
    //     {
    //       placement: "topEnd",
    //       duration: 2000,
    //     }
    //   );
    // }
  };
  return (
    <>
      <Button appearance="link" onClick={handleOpen}>
        Edit
      </Button>
      <Drawer open={open} onClose={handleClose} size="xs">
        <Drawer.Header>
          <Drawer.Title>Edit Website</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="">
            <p>Domain name: {data.domainName}</p>
            <p>URL: {data.websiteUrl}</p>
            {showAll && (
              <div className="">
                <p>Free Access</p>
                <Dropdown
                  className="dropdown-width"
                  title={modalData?.freeAccess?.toString()}
                  activeKey={modalData?.freeAccess}
                  onSelect={(e) =>
                    setModalData({ ...modalData, freeAccess: e })
                  }
                >
                  <Dropdown.Item eventKey={true}>True</Dropdown.Item>
                  <Dropdown.Item eventKey={false}>False</Dropdown.Item>
                </Dropdown>
              </div>
            )}
            {/*<div className="my-3">*/}
            {/*  <p>Domain Name</p>*/}
            {/*  <Input*/}
            {/*    onChange={(e) => setModalData({ ...modalData, domainName: e })}*/}
            {/*  />*/}
            {/*</div>*/}
            {showAll && (
              <div className="my-3">
                <p>DA</p>
                <Input
                  value={modalData.DA}
                  type="number"
                  onChange={(e) => setModalData({ ...modalData, DA: e })}
                />
              </div>
            )}
            {showAll && (
              <div className="my-3">
                <p>TAT</p>
                <Input
                  value={modalData.TAT}
                  type="number"
                  onChange={(e) => setModalData({ ...modalData, TAT: e })}
                />
              </div>
            )}
            {showAll && (
                <div className="my-3">
                  <p>DR</p>
                  <Input
                      value={modalData.DR}
                      type="number"
                      onChange={(e) =>
                          setModalData({ ...modalData, DR: e })
                      }
                  />
                </div>
            )}
            {showAll && (
              <div className="my-3">
                <p>Monthly Traffic</p>
                <Input
                  value={modalData.monthlyTraffic}
                  type="number"
                  defaultValue={null}
                  onChange={(e) =>
                    setModalData({ ...modalData, monthlyTraffic: e })
                  }
                />
              </div>
            )}

            <div className="my-3">
              <p>categories</p>
              <Select
                defaultValue={data?.categories.map((item) => ({
                  label: item,
                  value: item,
                }))}
                onChange={handleCategoryChange}
                isMulti
                name="colors"
                options={categoriesData}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
          <div className="my-3">
            <p>Guidelines</p>
            <textarea
              className="text-dark"
              name="guidelines"
              id=""
              cols="30"
              rows="10"
              value={modalData.guidelines}
              onChange={(e) =>
                setModalData({ ...modalData, guidelines: e.target.value })
              }
            />
          </div>
          <div>
            <Button onClick={handleUpdate} appearance="primary">
              UPDATE
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default WebsiteEditDrawer;
