import React, { useEffect, useState } from "react";
import {
  Container,
  Content,
  Dropdown,
  Header,
  Message,
  Pagination,
  Table,
  toaster,
} from "rsuite";
import VerticalNav from "../../components/VerticalNav";
import { getBankPayoutsList } from "../../services/BankServices";
import * as PropTypes from "prop-types";

function ActionCell(props) {
  return null;
}

ActionCell.propTypes = { dataKey: PropTypes.string };
const BankPayoutList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getBankPayoutsList();
      console.log(response.data);
      if (response.status == 200) {
        const newData = response?.data?.map((item, index) => ({
          ...item,
          id: index,
        }));
        setData(newData);
      } else {
        toaster.push(
          <Message showIcon type="error" header="Error">
            {response.response?.data?.message || "No Message Available"}
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
      }
    })();
  }, []);
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Bank Payout List</h2>
      </Header>
      <Content>
        {/* <div className="my-2">
          <p>Select Status</p>
          <Dropdown
            className="dropdown-width"
            title={status ? status : "Select"}
            onSelect={(e) => {
              setStatus(e);
            }}
          >
            <Dropdown.Item eventKey={""}>Select</Dropdown.Item>
            <Dropdown.Item eventKey={"FAILED"}>FAILED</Dropdown.Item>
            <Dropdown.Item eventKey={"INIT"}>INIT</Dropdown.Item>
            <Dropdown.Item eventKey={"PENDING"}>PENDING</Dropdown.Item>
            <Dropdown.Item eventKey={"SUCCESS"}>SUCCESS</Dropdown.Item>
          </Dropdown>
        </div> */}
        <Table height={window.innerHeight - 200} data={data}>
          <Table.Column width={150}>
            <Table.HeaderCell>User Id</Table.HeaderCell>
            <Table.Cell dataKey="userId" />
          </Table.Column>
          {/* <Table.Column width={150}>
            <Table.HeaderCell>Order Id</Table.HeaderCell>
            <Table.Cell dataKey="orderId" />
          </Table.Column> */}
          <Table.Column width={150}>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.Cell dataKey="amount" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Payout Amount</Table.HeaderCell>
            <Table.Cell dataKey="payoutAmount" />
          </Table.Column>

          {/* <Table.Column width={150}>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.Cell dataKey="date" />
          </Table.Column>

          <Table.Column width={150}>
            <Table.HeaderCell>status</Table.HeaderCell>
            <Table.Cell dataKey="status" />
          </Table.Column> */}
        </Table>
        {/* <div style={{ padding: 20 }}>
          <Pagination
            layout={["pager"]}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalPages}
            limit={1}
            maxButtons={13}
            activePage={pageNumber + 1}
            onChangePage={async (e) => {
              setPageNumber(e - 1);
            }}
          />
        </div> */}
      </Content>
    </Container>
  );
};

export default BankPayoutList;
