import React from "react";
import {
  Button,
  Container,
  Content,
  Dropdown,
  FlexboxGrid,
  Header,
  Message,
  Table,
  toaster,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import { getRecentPayouts } from "../services/DashboardService";
import exportFromJSON from "export-from-json";
import { AmountCell } from "../components/CustomCells";
import dayjs from "dayjs";

const RecentPayouts = () => {
  const [data, setData] = React.useState([]);
  const [recentPayoutDate, setRecentPayoutDate] = React.useState("");
  const [status, setStatus] = React.useState("");
  React.useEffect(() => {
    (async () => {
      let response = await getRecentPayouts(status);
      if (response.status === 200) {
        setData(response?.data?.recentPayoutDtoList);
        setRecentPayoutDate(response?.data?.recentPayoutDate);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response.statusText}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
    })();
  }, [status]);

  const ExportToExcel = () => {
    const DateTime = dayjs().format("DD_MM_YYYY_HH:mm");
    const fileName = `RecentPayoutsList_${DateTime}`;
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Recent Payout List</h2>
      </Header>
      <Content>
        <div className="m-3 d-flex">
          <div className="me-4">
            <p>Select Status</p>
            <Dropdown
              className="dropdown-width"
              title={status === "" ? "Select" : status}
              onSelect={(e) => setStatus(e)}
            >
              <Dropdown.Item eventKey="">Select</Dropdown.Item>
              <Dropdown.Item eventKey="FAILED">FAILED</Dropdown.Item>
              <Dropdown.Item eventKey="INIT">INIT</Dropdown.Item>
              <Dropdown.Item eventKey="PENDING">PENDING</Dropdown.Item>
              <Dropdown.Item eventKey="SUCCESS">SUCCESS</Dropdown.Item>
            </Dropdown>
          </div>
          <Button appearance="ghost" onClick={ExportToExcel}>
            Download Data (csv)
          </Button>
        </div>
        <p className="my-3"> Last Payout Date : {recentPayoutDate}</p>
        <Table height={window.innerHeight - 200} data={data}>
          <Table.Column width={150}>
            <Table.HeaderCell>User Id</Table.HeaderCell>
            <Table.Cell dataKey="userId" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Order Id</Table.HeaderCell>
            <Table.Cell dataKey="orderId" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Payout Type</Table.HeaderCell>
            <Table.Cell dataKey="payoutType" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell> Currency</Table.HeaderCell>
            <Table.Cell dataKey="payoutCurrency" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell> Amount</Table.HeaderCell>
            <Table.Cell dataKey="amount" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Payout Amount</Table.HeaderCell>
            <Table.Cell dataKey="payoutAmount" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.Cell dataKey="status" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Exchange Rate</Table.HeaderCell>
            <Table.Cell dataKey="exchangeRate" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Processing Fee</Table.HeaderCell>
            <Table.Cell dataKey="processingFee" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Conveyance Fee</Table.HeaderCell>
            <Table.Cell dataKey="conveyanceFee" />
          </Table.Column>
        </Table>
      </Content>
    </Container>
  );
};

export default RecentPayouts;
