import React, { useEffect, useState } from "react";
import HomePage from "./pages/Homepage";
import LoginPage from "./pages/LoginPage";
import { CustomProvider, Loader } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import RegisterPage from "./pages/RegisterPage";
import FinishSetup from "./pages/SetPassword";
import ForgetPassword from "./pages/ForgetPassword";
import ErrorPage from "./pages/ErrorPage";
import { fetchJWTToken } from "./utils/auth";
import UserPasswordReset from "./pages/UserPasswordReset";
import UsersList from "./pages/UsersList";
import UserSavedFilters from "./pages/UserSavedFilters";
import UserWebsiteList from "./pages/UserWebsiteList";
import WebsitesList from "./pages/WebsitesList";
import DomainVerification from "./pages/DomainVerification";
import RecentUpdateActions from "./pages/RecentUpdateActions";
import PostOrders from "./pages/PostOrders";
import DashboardContentOrder from "./pages/DashboardContentOrder";
import ContentOrders from "./pages/ContentOrders";
import CacheReload from "./pages/CacheReload";
import UploadImage from "./pages/UploadImage";
import UploadConfig from "./pages/UploadConfig";
import ContactUs from "./pages/ContactUs";
import PaypalPayoutList from "./pages/paypal/PaypalPayoutListPage";
import PaypalPayoutInitiate from "./pages/paypal/PaypalPayoutInitiate";
import PaypalPayoutSettlements from "./pages/paypal/PaypalPayoutSettlements";
import BankPayoutAccounts from "./pages/bank/BankPayoutAccounts";
import BankPayoutList from "./pages/bank/BankPayoutList";
import RecentPayouts from "./pages/RecentPayouts";
import RecentWebsites from "./pages/ApprovalPendingWebsites";
import BankSettlement from "./pages/bank/BankSettlement";
import PaypalPayoutPending from "./pages/paypal/PaypalPayoutPending";
import ScrollToTop from "./components/ScrollToTop";
import PaymentOrders from "./pages/PaymentOrderList";
import { getUserDetails } from "./services/UserService";
import { setUserProfileData } from "./redux/ActionCreator";
import { RouteNames } from "./constants/Routes";
import DashboardContainer from "./components/DashboardContainer";
import ApprovalPendingWebsites from "./pages/ApprovalPendingWebsites";
import DAPendingWebsites from "./pages/DAPendingWebsites";
import PayoutListing from './pages/PayoutListing';
import BankPandingList from './pages/bank/BankPandingList';
import PaypalInvoiceList from "./pages/PaypalInvoiceList";
function App() {
  const theme = useSelector((state) => state.theme);
  let token = fetchJWTToken();
  const dispatch = useDispatch();
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(window.navigator.onLine, "oneline status");
    (async () => {
      setLoading(true);
      if (token) {
        let response = await getUserDetails(authenticated);
        if (response.status === 200) {
          dispatch(setUserProfileData(response.data));
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <CustomProvider theme={theme}>
      <BrowserRouter>
        <div className="env-text">
          <p>{process.env.REACT_APP_ENVIRONMENT} ENVIRONMENT</p>
          {/*<p>*/}
          {/*  {process.env.REACT_APP_ENVIRONMENT === "DEV"*/}
          {/*    ? "DEVELOPMENT ENVIRONMENT"*/}
          {/*    : "PRODUCTION ENVIRONMENT"}*/}
          {/*</p>*/}
        </div>
        <ScrollToTop>
          {loading ? (
            <div className="vh-100 d-flex justify-content-center align-items-center">
              <Loader size="lg" content="Loading ..." />
            </div>
          ) : (
            <Routes>
              <Route
                path={RouteNames.HOME}
                element={
                  authenticated ? (
                    <DashboardContainer component={<HomePage />} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path={RouteNames.LOGIN}
                element={authenticated ? <Navigate to="/" /> : <LoginPage />}
              />
              <Route
                path={RouteNames.REGISTER}
                element={authenticated ? <Navigate to="/" /> : <RegisterPage />}
              />
              <Route
                path={RouteNames.FINISH_SETUP}
                element={authenticated ? <Navigate to="/" /> : <FinishSetup />}
              />
              <Route
                path={RouteNames.FORGET_PASSWORD}
                element={
                  authenticated ? <Navigate to="/" /> : <ForgetPassword />
                }
              />
              <Route path={RouteNames.ERROR_PAGE} element={<ErrorPage />} />
              <Route
                path={RouteNames.HOME_CONTENT_ORDER}
                element={
                  authenticated ? (
                    <DashboardContainer component={<DashboardContentOrder />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.RESET_PASSWORD}
                element={
                  authenticated ? (
                    <DashboardContainer component={<UserPasswordReset />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.USERS}
                element={
                  authenticated ? (
                    <DashboardContainer component={<UsersList />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.USER_FILTERS}
                element={
                  authenticated ? (
                    <DashboardContainer component={<UserSavedFilters />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.USER_WEBSITES}
                element={
                  authenticated ? (
                    <DashboardContainer component={<UserWebsiteList />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.WEBSITES}
                element={
                  authenticated ? (
                    <DashboardContainer component={<WebsitesList />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.DA_PENDING_WEBSITES}
                element={
                  authenticated ? (
                    <DashboardContainer component={<DAPendingWebsites />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.APPROVAL_PENDING_WEBSITES}
                element={
                  authenticated ? (
                    <DashboardContainer
                      component={<ApprovalPendingWebsites />}
                    />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.VERIFY_DOMAIN}
                element={
                  authenticated ? (
                    <DashboardContainer component={<DomainVerification />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.RECENT_UPDATES}
                element={
                  authenticated ? (
                    <DashboardContainer component={<RecentUpdateActions />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.POST_ORDERS}
                element={
                  authenticated ? (
                    <DashboardContainer component={<PostOrders />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.CONTENT_ORDERS}
                element={
                  authenticated ? (
                    <DashboardContainer component={<ContentOrders />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.CACHE_RESET}
                element={
                  authenticated ? (
                    <DashboardContainer component={<CacheReload />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.UPLOAD_IMAGE}
                element={
                  authenticated ? (
                    <DashboardContainer component={<UploadImage />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.UPLOAD_CONFIG}
                element={
                  authenticated ? (
                    <DashboardContainer component={<UploadConfig />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.CONTACT_US}
                element={
                  authenticated ? (
                    <DashboardContainer component={<ContactUs />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.PAYPAL_PAYOUT_LIST}
                element={
                  authenticated ? (
                    <DashboardContainer component={<PaypalPayoutList />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.PAYPAL_INITIATE_PAYOUT}
                element={
                  authenticated ? (
                    <DashboardContainer component={<PaypalPayoutInitiate />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.PAYPAL_PENDING_PAYOUT}
                element={
                  authenticated ? (
                    <DashboardContainer component={<PaypalPayoutPending />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.PAYPAL_SETTLEMENT}
                element={
                  authenticated ? (
                    <DashboardContainer
                      component={<PaypalPayoutSettlements />}
                    />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.BANK_PAYMENT_ACCOUNTS}
                element={
                  authenticated ? (
                    <DashboardContainer component={<BankPayoutAccounts />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.BANK_PAYOUT_LISTING}
                element={
                  authenticated ? (
                    <DashboardContainer component={<BankPayoutList />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.RECENT_PAYOUT}
                element={
                  authenticated ? (
                    <DashboardContainer component={<RecentPayouts />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.BANK_SETTLEMENT}
                element={
                  authenticated ? (
                    <DashboardContainer component={<BankSettlement />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.PAYMENT_ORDERS}
                element={
                  authenticated ? (
                    <DashboardContainer component={<PaymentOrders />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.PAYPAL_INVOICE_LIST}
                element={
                  authenticated ? (
                    <DashboardContainer component={<PaypalInvoiceList />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.PAYOUT_LISTING}
                element={
                  authenticated ? (
                    <DashboardContainer component={<PayoutListing />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path={RouteNames.BANK_PENDING_LISTING}
                element={
                  authenticated ? (
                    <DashboardContainer component={<BankPandingList />} />
                  ) : (
                    <LoginPage />
                  )
                }
              />

            </Routes>
            
          )}
        </ScrollToTop>
      </BrowserRouter>
    </CustomProvider>
  );
}

export default App;
