import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  FlexboxGrid,
  Footer,
  Form,
  Header,
  Message,
  Panel,
  Schema,
  toaster,
} from "rsuite";
import NavBar from "../components/Navbar";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../services/UserService";
import { RouteNames } from "../constants/Routes";

const ForgetPassword = () => {
  const [formValue, setFormValue] = React.useState({
    email: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    email: Schema.Types.StringType()
      .isEmail("Please enter a valid email address.")
      .isRequired("This Field is required."),
  });
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    formRef.current.check()
      ? forgetPasswordRequest()
      : toaster.push(
          <Message showIcon type="error" header="Error">
            Please Validate all Fields
          </Message>,
          {
            placement: "topEnd",
          }
        );
  };
  const forgetPasswordRequest = async () => {
    const response = await resetPassword({ email: formValue.email });
    if (response.status === 200) {
      toaster.push(
        <Message showIcon type="success" header="Error">
          Password Reset Link Sent Successfully!
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
      setSuccess(true);
      setTimeout(() => {
        navigate(RouteNames.LOGIN, { replace: true });
      }, 2000);
    }
  };
  return (
    <Container className="vh-100 d-flex flex-column justify-content-between ">
      <Header>
        <NavBar />
      </Header>
      <Content className=" d-flex flex-column justify-content-center">
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={8}>
            <Panel header={<h3>Forget Password ?</h3>} bordered>
              <h5 className="mb-3">Rest your password now</h5>
              <Form
                ref={formRef}
                fluid
                onSubmit={handleSubmit}
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
              >
                <TextInput label="Email" name={"email"} />
                <Form.Group>
                  <ButtonToolbar>
                    <Button
                      disabled={success}
                      type="submit"
                      appearance="primary"
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(RouteNames.LOGIN, { replace: true })
                      }
                      appearance="link"
                    >
                      Back to Login
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
      <Footer>Footer</Footer>
    </Container>
  );
};

export default ForgetPassword;
