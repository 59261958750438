import React, { useEffect } from "react";
import {
  Button,
  Drawer,
  Dropdown,
  Message,
  Modal,
  Table,
  toaster,
} from "rsuite";
import { updateUser } from "../services/DashboardService";

const UserEditModal = ({ data, update, updateTrigger }) => {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  useEffect(() => {
    setModalData({
      inventoryAccess: data.inventoryAccess,
      preference: data.preference,
      resetInventoryPrefs: false,
      userEnabled: data.enabled,
      userUniqueId: data.userUniqueId,
      userPaymentType: data.userPaymentType
    });
  }, [update, open, updateTrigger]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdate = async () => {
    const response = await updateUser(modalData);
    if (response.status === 200) {
      update();
      toaster.push(
        <Message showIcon type="success" header="Success">
          User Updated Successfully
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
      handleClose();
    }
    // else {
    //   toaster.push(
    //     <Message showIcon type="error" header="Error">
    //       {response.response?.data?.message || "No Message Available"}
    //     </Message>,
    //     {
    //       placement: "topEnd",
    //       duration: 2000,
    //     }
    //   );
    // }
  };
  return (
    <>
      <Button appearance="link" onClick={handleOpen}>
        Edit
      </Button>
      <Drawer open={open} onClose={handleClose} size="xs">
        <Drawer.Header>
          <Modal.Title>Edit User</Modal.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div>
            <div>Name: {data?.name}</div>
            <div>Email: {data?.email}</div>
          </div>
          <div className="">
            <div className="my-3">
              <p>Inventory Access</p>
              <Dropdown
                className="dropdown-width"
                title={modalData?.inventoryAccess?.toString()}
                activeKey={modalData?.inventoryAccess}
                onSelect={(e) =>
                  setModalData({ ...modalData, inventoryAccess: e })
                }
              >
                <Dropdown.Item eventKey={true}>true</Dropdown.Item>
                <Dropdown.Item eventKey={false}>false</Dropdown.Item>
              </Dropdown>
            </div>
            <div className="my-3">
              <p>Preference</p>
              <Dropdown
                className="dropdown-width"
                title={modalData.preference ? modalData.preference : "NA"}
                activeKey={modalData.preference}
                onSelect={(e) => setModalData({ ...modalData, preference: e })}>
                <Dropdown.Item eventKey={"NA"}>NA</Dropdown.Item>
                <Dropdown.Item eventKey={"ADVERTISER"}>ADVERTISER</Dropdown.Item>
                <Dropdown.Item eventKey={"PUBLISHER"}>PUBLISHER</Dropdown.Item>
              </Dropdown>
            </div>
            <div className="my-3">
              <p>Reset Inventory Prefs</p>
              <Dropdown
                className="dropdown-width"
                title={modalData.resetInventoryPrefs?.toString()}
                activeKey={modalData.resetInventoryPrefs}
                onSelect={(e) =>
                  setModalData({ ...modalData, resetInventoryPrefs: e })
                }
              >
                <Dropdown.Item eventKey={true}>true</Dropdown.Item>
                <Dropdown.Item eventKey={false}>false</Dropdown.Item>
              </Dropdown>
            </div>
            <div className="my-3">
              <p>User Enabled</p>
              <Dropdown
                className="dropdown-width"
                title={modalData.userEnabled?.toString()}
                activeKey={modalData.userEnabled}
                onSelect={(e) => setModalData({ ...modalData, userEnabled: e })}
              >
                <Dropdown.Item eventKey={true}>true</Dropdown.Item>
                <Dropdown.Item eventKey={false}>true</Dropdown.Item>
              </Dropdown>
            </div>
            <div className="my-3">
              <p>User Payment Type</p>
              <Dropdown
                  className="dropdown-width"
                  title={modalData.userPaymentType}
                  activeKey={modalData.userPaymentType}
                  onSelect={(e) => setModalData({ ...modalData, userPaymentType: e })}
              >
                <Dropdown.Item eventKey={"PREPAY"}>PREPAY</Dropdown.Item>
                <Dropdown.Item eventKey={"POSTPAY"}>POSTPAY</Dropdown.Item>
              </Dropdown>
            </div>
          </div>

          <div>
            <Button onClick={handleUpdate} appearance="primary">
              UPDATE
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default UserEditModal;
