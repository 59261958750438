import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Content,
  Header,
  Message,
  Table,
  toaster,
} from "rsuite";
import VerticalNav from "../../components/VerticalNav";

import { getPaypalPayoutList } from "../../services/PaypalServices";
import exportFromJSON from "export-from-json";
import { AmountCell } from "../../components/CustomCells";
import dayjs from "dayjs";

const PaypalPayoutList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getPaypalPayoutList();
      console.log(response.data);
      if (response.status == 200) {
        setData(response.data);
      } else {
        toaster.push(
          <Message showIcon type="error" header={response.status}>
            {response.statusText}
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
      }
    })();
  }, []);

  const ExportToExcel = () => {
    const DateTime = dayjs().format("DD_MM_YYYY_HH:mm");
    const fileName = `PaypalPayoutList_${DateTime}`;
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Paypal Payout List</h2>
      </Header>
      <Content>
        <div className="m-3">
          <Button appearance="ghost" onClick={ExportToExcel}>
            Download Data (csv)
          </Button>
        </div>
        <Table height={window.innerHeight - 200} data={data}>
          <Table.Column width={150}>
            <Table.HeaderCell>User Id</Table.HeaderCell>
            <Table.Cell dataKey="userId" />
          </Table.Column>

          <Table.Column width={150}>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.Cell dataKey="amount" />
          </Table.Column>

          <Table.Column width={350}>
            <Table.HeaderCell>Paypal Address</Table.HeaderCell>
            <Table.Cell dataKey="paypalAddress" />
          </Table.Column>
        </Table>
      </Content>
    </Container>
  );
};

export default PaypalPayoutList;
