import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Content,
  DateRangePicker,
  FlexboxGrid,
  Header,
  Input,
  InputGroup,
  Message,
  Pagination,
  Panel,
  toaster,
} from "rsuite";
import { getUsersList } from "../services/DashboardService";
import { useNavigate } from "react-router-dom";
import VerticalNav from "../components/VerticalNav";
import { Icon } from "@rsuite/icons";
import { FiSearch } from "react-icons/fi";
import UserEditModal from "../components/UserEditModal";
import ViewUserDataDrawer from "../components/ViewUserDataDrawer";
import { RouteNames } from "../constants/Routes";
const UserList = () => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const response = await getUsersList(
        pageNumber,
        pageSize,
        searchText,
        endDate,
        startDate
      );
      console.log(response.data);
      if (response.status === 200) {
        setPageNumber(response?.data?.pageNumber);
        setTotalPages(response?.data?.totalPages);
        const newData = response?.data?.userResponseList.map((item, index) => ({
          ...item,
          id: index,
        }));
        setData(newData);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header="Error">
      //       {response.response?.data?.message || "No Message Available"}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
    })();
  }, [startDate, endDate, updateTrigger]);

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setPageNumber(0);
      setUpdateTrigger(!updateTrigger);
    }
  };

  return (
    <Container>
      <Header>
        <h2 className="ms-2">User List</h2>
      </Header>
      <Content>
        <FlexboxGrid
          justify="space-between"
          align="middle"
          className="mx-3 mb-3"
        >
          <div className="d-flex">
            <div>
              <p>Select Date Interval</p>
              <DateRangePicker
                onChange={(e) => {
                  if (e === null) {
                    setStartDate("");
                    setEndDate("");
                  } else {
                    setStartDate(
                      `${e[0].getDate()}-${
                        e[0].getMonth() + 1
                      }-${e[0].getFullYear()}`
                    );
                    setEndDate(
                      `${e[1].getDate()}-${
                        e[1].getMonth() + 1
                      }-${e[1].getFullYear()}`
                    );
                  }
                }}
                format={"dd-MM-yyyy"}
              />
            </div>
            <div className="ms-2">
              <p>Search</p>
              <div className="d-flex align-items-center">
                <InputGroup>
                  <Input
                    value={searchText}
                    onKeyDown={_handleKeyDown}
                    onChange={(e) => {
                      setSearchText(e);
                    }}
                  />
                  <InputGroup.Button
                    onClick={() => {
                      setPageNumber(0);
                      setUpdateTrigger(!updateTrigger);
                    }}
                  >
                    <Icon as={FiSearch} />
                  </InputGroup.Button>
                </InputGroup>
                <p
                  className="c-ptr ms-3"
                  onClick={async () => {
                    await setSearchText("");
                    setPageNumber(0);
                    setUpdateTrigger(!updateTrigger);
                  }}
                >
                  CLEAR
                </p>
              </div>
            </div>
          </div>
          <Pagination
            layout={["pager"]}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalPages}
            limit={1}
            maxButtons={8}
            activePage={pageNumber + 1}
            onChangePage={async (e) => {
              await setPageNumber(e - 1);
              setUpdateTrigger(!updateTrigger);
            }}
          />
        </FlexboxGrid>
        {data.map((item) => (
          <Panel bordered className="listPanel">
            <div className="row" style={{ padding: 1 }}>
              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12">
                    <span>User Unique Id:</span>
                    <span>{item?.userUniqueId}</span>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12">
                    <span>Name:</span>
                    <span>{item?.name}</span>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <span>Email:</span>
                    <span>{item?.email}</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-xl-1 col-lg-6 col-md-12">
                    <span>UserId:</span>
                    <span>{item.userId}</span>
                  </div>
                  <div className="col-xl-1 col-lg-6 col-md-12">
                    <span>Enabled:</span>
                    <span>{item?.enabled?.toString()}</span>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12">
                    <span>Preference:</span>
                    <span>{item?.preference}</span>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12">
                    <span>Inv. Access:</span>
                    <span>{item?.inventoryAccess?.toString()}</span>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <span>Inventory Prefs:</span>
                    <span>{item?.inventoryPrefs?.toString()}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <ViewUserDataDrawer data={item} />
                <UserEditModal
                  updateTrigger={updateTrigger}
                  data={item}
                  update={() => setUpdateTrigger(!updateTrigger)}
                />
                <Button
                  appearance="link"
                  onClick={() => {
                    navigate(RouteNames.USER_FILTERS, {
                      state: { userUniqueId: item.userUniqueId },
                    });
                  }}
                >
                  Filters
                </Button>
                <Button
                  appearance="link"
                  onClick={() => {
                    navigate(RouteNames.USER_WEBSITES, {
                      state: { userUniqueId: item.userUniqueId },
                    });
                  }}
                >
                  Websites
                </Button>
              </div>
            </div>
          </Panel>
        ))}
        <div style={{ padding: 20 }}>
          <Pagination
            layout={["pager"]}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalPages}
            limit={1}
            maxButtons={13}
            activePage={pageNumber + 1}
            onChangePage={async (e) => {
              await setPageNumber(e - 1);
              setUpdateTrigger(!updateTrigger);
            }}
          />
        </div>
      </Content>
    </Container>
  );
};
export default UserList;
