import React, { useEffect } from "react";
import { Button, Drawer, Dropdown, Input, Message, toaster } from "rsuite";
import {
  updateWebsite,
  updateWebsiteStatus,
} from "../services/DashboardService";

const WebsiteStatusUpdateDrawer = ({ data, update }) => {
  console.log(data, "data");
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  useEffect(() => {
    setModalData({
      actionType: data.actionType,
      domainName: data.domainName,
      reason: "",
      websiteId: data.id,
    });
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onUpdateTrigger = async () => {
    const response = await updateWebsiteStatus(modalData);
    if (response.status == 200) {
      update();
      toaster.push(
        <Message showIcon type="success" header="Success">
          Website status updated Successfully
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
      handleClose();
    }
    // else {
    //   toaster.push(
    //     <Message showIcon type="error" header="Error">
    //       {response?.response?.data?.message || "No Message Available"}
    //     </Message>,
    //     {
    //       placement: "topEnd",
    //       duration: 2000,
    //     }
    //   );
    // }
  };
  return (
    <>
      <Button appearance="link" onClick={handleOpen}>
        Change Status
      </Button>
      <Drawer open={open} onClose={handleClose} size="xs">
        <Drawer.Header>
          <Drawer.Title>Update Website Status</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="my-3">
            <p>{modalData.domainName}</p>
          </div>

          <div className=" mt-2">
            <p>Action Type</p>
            <Dropdown
              className="dropdown-width"
              title={modalData?.actionType ? modalData.actionType : "Select"}
              activeKey={modalData?.actionType}
              onSelect={(e) => setModalData({ ...modalData, actionType: e })}
            >
              <Dropdown.Item eventKey="">Select</Dropdown.Item>
              <Dropdown.Item eventKey="ACTIVATED">ACTIVATED</Dropdown.Item>
              <Dropdown.Item eventKey="APPROVED">APPROVED</Dropdown.Item>
              <Dropdown.Item eventKey="DEACTIVATED">DEACTIVATED</Dropdown.Item>
              <Dropdown.Item eventKey="DISABLED">DISABLED</Dropdown.Item>
              <Dropdown.Item eventKey="REJECTED">REJECTED</Dropdown.Item>
              <Dropdown.Item eventKey="REMOVED">REMOVED</Dropdown.Item>
            </Dropdown>
          </div>
          <div className="my-3">
            <p>Reason</p>
            <Input
              onChange={(e) => setModalData({ ...modalData, reason: e })}
            />
          </div>
          <div className="my-3">
            <Button appearance="primary" onClick={onUpdateTrigger}>
              UPDATE
            </Button>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default WebsiteStatusUpdateDrawer;
