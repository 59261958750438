import axios from "axios";
import { fetchJWTToken, handle401 } from "../utils/auth";
import Endpoints from "../constants/Endpoints";
import { Message, toaster } from "rsuite";
import React from "react";
import { handleErrorBlock } from "../utils/utils";

const token = fetchJWTToken();
let config = {
  headers: {
    authorization: token,
  },
};

export const getHomepagePostOrders = async (startDate, endDate, type) => {
  try {
    return await axios.get(
      `${Endpoints.DASHBOARD_POST_ORDER}?status=${type}&startDate=${startDate}&endDate=${endDate}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    // toaster.push(
    //   <Message showIcon type="error" header="Error">
    //     {err.response?.data?.message || "No Message Available"}
    //   </Message>,
    //   {
    //     placement: "topEnd",
    //     duration: 2000,
    //   }
    // );
    if (err.response.status === 401) {
      handle401();
    }

    return err;
  }
};

export const getHomepageContentOrders = async (startDate, endDate, type) => {
  try {
    return await axios.get(
      `${Endpoints.DASHBOARD_CONTENT_ORDER}?status=${type}&startDate=${startDate}&endDate=${endDate}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const userPasswordResetRequest = async (data) => {
  try {
    return await axios.post(Endpoints.USER_FORGET_PASSWORD, data, config);
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getUsersList = async (
  pageNumber,
  pageSize,
  searchText,
  endDate,
  startDate
) => {
  try {
    return await axios.get(
      `${Endpoints.USERS_LIST}?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const updateUser = async (data) => {
  try {
    return await axios.put(Endpoints.USERS_UPDATE, data, {
      headers: {
        authorization: token,
      },
    });
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getInventoryPrefsList = async (id) => {
  try {
    return await axios.get(
      `${Endpoints.USER_PREF_LIST}?userUniqueId=${id}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getUserWebsiteList = async (id) => {
  try {
    return await axios.get(
      `${Endpoints.USER_WEBSITE_LIST}?userUniqueId=${id}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const updateWebsite = async (data) => {
  try {
    return await axios.put(Endpoints.WEBSITES_UPDATE, data, {
      headers: {
        authorization: token,
      },
    });
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const updateWebsiteStatus = async (data) => {
  try {
    return await axios.put(Endpoints.WEBSITE_STATUS_UPDATE, data, {
      headers: {
        authorization: token,
      },
    });
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const verifyDomain = async (data) => {
  try {
    return await axios.post(
      `${Endpoints.VERIFY_DOMAIN}`,
      {
        domainName: data.domainName,
        userEmail: data.userEmail,
        websiteUrl: data.websiteUrl,
      },
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getRecentUpdateActions = async (data) => {
  try {
    return await axios.put(
      `${Endpoints.RECENT_UPDATES}?recordSize=${data.recordSize}`,
      {},
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getPostOrders = async (
  startDate,
  endDate,
  type,
  pageNumber,
  pageSize
) => {
  try {
    return await axios.get(
      `${Endpoints.POST_ORDER}?status=${type}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=10`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getPostOrdersDetails = async (orderId) => {
  try {
    return await axios.get(
      `${Endpoints.POST_ORDER_DETAILS}?orderId=${orderId}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getContentOrdersDetails = async (orderId) => {
  try {
    return await axios.get(
      `${Endpoints.CONTENT_ORDER_DETAILS}?orderId=${orderId}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getContentOrders = async (
  startDate,
  endDate,
  type,
  pageNumber,
  pageSize
) => {
  try {
    return await axios.get(
      `${Endpoints.CONTENT_ORDER}?status=${type}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getCacheReset = async (type) => {
  try {
    return await axios.get(`${Endpoints.CACHE_RESET}?type=${type}`, config);
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getContactUsList = async (pageNumber, pageSize, status) => {
  try {
    return await axios.get(
      `${Endpoints.CONTACT_US_LIST}?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const putCloseAllTickets = async () => {
  try {
    return await axios.put(`${Endpoints.CONTACT_US_UPDATE_ALL}`, {}, config);
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const putUpdateContactTicket = async (id, status) => {
  try {
    return await axios.put(
      `${Endpoints.CONTACT_US}${id}?status=${status}`,
      {},
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const updatePostOrder = async (id, actionType, reason) => {
  try {
    return await axios.post(
      `${Endpoints.POST_ORDER_ACTION}`,
      { actionType, reason, orderId: id },
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const updatePaypalInvoice = async (
  invoiceNumber,
  paypalInvoiceStatus,
  status
) => {
  try {
    return await axios.put(
      `${Endpoints.PAYPAL_INVOICE_ACTION}${invoiceNumber}?paypalInvoiceStatus=${paypalInvoiceStatus}&status=${status}`,
      {},
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const updateContentOrder = async (id, actionType, reason) => {
  try {
    return await axios.post(
      `${Endpoints.CONTENT_ORDER_ACTION}`,
      { actionType, reason, orderId: id },
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getRecentPayouts = async (status) => {
  try {
    return await axios.get(
      `${Endpoints.RECENT_PAYOUT}?payoutStatus=${status}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getPaymentOrderList = async (pageNumber, status) => {
  try {
    return await axios.get(
      `${Endpoints.PAYMENT_ORDERS_LIST}?pageNumber=${pageNumber}&pageSize=10&status=${status}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};

export const getPaypalInvoiceList = async (pageNumber, status) => {
  try {
    return await axios.get(
      `${Endpoints.PAYPAL_INVOICE_LIST}?pageNumber=${pageNumber}&pageSize=10&paypalInvoiceStatus=${status}`,
      config
    );
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};
