import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  Form,
  Header,
  List,
  Message,
  Panel,
  Schema,
  toaster,
} from "rsuite";
import VerticalNav from "../components/VerticalNav";
import TextInput from "../components/TextInput";
import { getRecentUpdateActions } from "../services/DashboardService";

const RecentUpdateActions = () => {
  const [formValue, setFormValue] = React.useState({
    recordSize: null,
  });
  const [formError, setFormError] = React.useState({});
  const formRef = React.useRef();
  const [data, setData] = useState([]);
  const model = Schema.Model({
    recordSize: Schema.Types.NumberType().isRequired("This Field is required."),
  });
  const handleSubmit = () => {
    formRef.current.check()
      ? handleRecentRecords()
      : toaster.push(
          <Message showIcon type="error" header="Error">
            Please Validate all Fields
          </Message>,
          {
            placement: "topEnd",
          }
        );
  };

  const handleRecentRecords = async () => {
    const response = await getRecentUpdateActions(formValue);
    setData(response.data);
    if (response.status == 200) {
      toaster.push(
        <Message showIcon type="success" header="Error">
          Response success
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    } else {
      toaster.push(
        <Message showIcon type="error" header="Error">
          {response.response?.data?.message || "No Message Available"}
        </Message>,
        {
          placement: "topEnd",
          duration: 2000,
        }
      );
    }
  };
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Show Recent Update Actions</h2>
      </Header>
      <Content className="d-flex flex-column align-items-center">
        <Panel bordered className="mt-3 w-50" style={{ maxWidth: 500 }}>
          <h4>Enter Record Size</h4>

          <Form
            ref={formRef}
            fluid
            onSubmit={handleSubmit}
            onChange={setFormValue}
            onCheck={setFormError}
            formValue={formValue}
            model={model}
          >
            <TextInput label="recordSize" name={"recordSize"} />
            <Form.Group>
              <ButtonToolbar>
                <Button type="submit" appearance="primary">
                  Submit
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>
        {data?.length > 0 && (
          <Panel bordered className="w-50 mt-2" header="Output :">
            <List>
              {data.map((item, index) => (
                <List.Item key={index} index={index}>
                  {item}
                </List.Item>
              ))}
            </List>
          </Panel>
        )}
      </Content>
    </Container>
  );
};

export default RecentUpdateActions;
