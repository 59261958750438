import axios from "axios";
import { checkOS } from "./Helpers";
import Endpoints from "../constants/Endpoints";
import { fetchJWTToken, handle401 } from "../utils/auth";
import { handleErrorBlock } from "../utils/utils";
const token = fetchJWTToken();

let config = {
  headers: {
    authorization: token,
  },
};
export const loginWithEmail = async (data) => {
  try {
    return await axios.post(Endpoints.EMAIL_LOGIN, data, {
      headers: {
        //these headers will be dynamic
        deviceId: "DEVICE_ID_132465",
        platform: checkOS(),
      },
    });
  } catch (err) {
    handleErrorBlock(err);
    return err;
  }
};

export const registerEmail = async (data) => {
  try {
    return await axios.post(Endpoints.REGISTER_EMAIL, data);
  } catch (err) {
    handleErrorBlock(err);
    return err;
  }
};

export const resetPassword = async (data) => {
  try {
    return await axios.post(Endpoints.FORGOT_PASSWORD, data);
  } catch (err) {
    handleErrorBlock(err);
    return err;
  }
};

export const verifyToken = async (data) => {
  try {
    return await axios.get(`${Endpoints.VERIFY_TOKEN}`);
  } catch (err) {
    handleErrorBlock(err);
    return err;
  }
};

export const setPassword = async (data) => {
  try {
    return await axios.post(Endpoints.VERIFY_EMAIL, data, {
      headers: {
        //these headers will be dynamic
        deviceId: "DEVICE_ID_132465",
        platform: checkOS(),
      },
    });
  } catch (err) {
    handleErrorBlock(err);
    return err;
  }
};

export const getUserDetails = async (token) => {
  try {
    return await axios.get(`${Endpoints.USER_DETAILS}`, config);
  } catch (err) {
    handleErrorBlock(err);
    if (err.response.status === 401) {
      handle401();
    }
    return err;
  }
};
