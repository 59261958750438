import React, { useEffect, useState } from "react";
import { Button, Drawer, Message, Modal, toaster } from "rsuite";
import {
  getContentOrdersDetails,
  getPostOrdersDetails,
} from "../services/DashboardService";

const OrderDetailsDrawer = ({ data, type }) => {
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = useState([]);

  const handleOpen = async () => {
    // setOpen(true);
    const response =
      type === "post"
        ? await getPostOrdersDetails(data.orderId)
        : await getContentOrdersDetails(data.orderId);
    console.log(response.data);
    if (response.status === 200) {
      setOpen(true);
      setDetails(response?.data);
    }
    // else {
    //   toaster.push(
    //     <Message showIcon type="error" header="Error">
    //       {response.response?.data?.message || "No Message Available"}
    //     </Message>,
    //     {
    //       placement: "topEnd",
    //       duration: 2000,
    //     }
    //   );
    // }
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button appearance="link" onClick={handleOpen}>
        MORE DETAILS
      </Button>{" "}
      <Drawer open={open} onClose={handleClose} size="sm">
        <Drawer.Header>
          <Modal.Title>Details</Modal.Title>
        </Drawer.Header>
        <Drawer.Body>
          {Object.entries(details).map(([key, value]) => (
            <div className="d-flex mb-2 ">
              <p style={{ width: 200 }} className="m-0">
                {key}:
              </p>
              <p style={{ maxWidth: 200 }} className=" m-0 text-wrap">
                {typeof value === "object"
                  ? JSON.stringify(value)?.toString()
                  : value?.toString()}
              </p>
            </div>
          ))}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default OrderDetailsDrawer;
