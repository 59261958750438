import React, { useEffect, useState } from "react";
import { Button, Container, Content, Header, Message, toaster } from "rsuite";
import VerticalNav from "../components/VerticalNav";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserWebsiteList } from "../services/DashboardService";
import WebsiteDataPanel from "../components/WebsiteDataPanel";
import { RouteNames } from "../constants/Routes";

const UserWebsiteList = (props) => {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getUserWebsiteList(state.userUniqueId);
      console.log(response.data.responseList);
      if (response.status == 200) {
        const newData = response?.data?.responseList;
        setData(newData);
      } else {
        toaster.push(
          <Message showIcon type="error" header="Error">
            {response.response?.data?.message || "No Message Available"}
          </Message>,
          {
            placement: "topEnd",
            duration: 2000,
          }
        );
      }
    })();
  }, []);

  const navigate = useNavigate();
  return (
    <Container>
      <Header className="d-flex justify-content-between">
        <h2 className="m-2">User Website List</h2>
        <Button
          appearance="link"
          className="me-5"
          onClick={() => {
            navigate(RouteNames.USERS);
          }}
        >
          &larr; Go Back
        </Button>
      </Header>
      <Content>
        {data.map((item) => (
          <WebsiteDataPanel item={item} type="view-only" />
        ))}
      </Content>
    </Container>
  );
};

export default UserWebsiteList;
