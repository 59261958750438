import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Content,
  Header,
  Message,
  Table,
  toaster,
} from "rsuite";
import { getBankPaymentAccountsList } from "../../services/BankServices";
import exportFromJSON from "export-from-json";
import { AmountCell } from "../../components/CustomCells";
import ViewUserDataDrawer from "../../components/ViewUserDataDrawer";
import dayjs from "dayjs";

const BankPayoutAccounts = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getBankPaymentAccountsList();
      console.log(response.data);
      if (response.status === 200) {
        setData(response.data);
      }
      // else {
      //   toaster.push(
      //     <Message showIcon type="error" header={response.status}>
      //       {response.statusText}
      //     </Message>,
      //     {
      //       placement: "topEnd",
      //       duration: 2000,
      //     }
      //   );
      // }
    })();
  }, []);

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} style={{ padding: "4px" }}>
        <ViewUserDataDrawer data={rowData.paymentBankDetails} />
      </Table.Cell>
    );
  };

  const ExportToExcel = () => {
    const DateTime = dayjs().format("DD_MM_YYYY_HH:mm");
    const fileName = `BankPayoutAccounts_${DateTime}`;
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Bank Payout Accounts List</h2>
      </Header>
      <Content>
        <div className="m-3">
          <Button appearance="ghost" onClick={ExportToExcel}>
            Download Data (csv)
          </Button>
        </div>
        <Table height={window.innerHeight - 200} data={data}>
          <Table.Column width={150}>
            <Table.HeaderCell>User id</Table.HeaderCell>
            <Table.Cell dataKey="userId" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>primary payout type</Table.HeaderCell>
            <Table.Cell dataKey="primaryPayoutType" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>View</Table.HeaderCell>
            <ActionCell dataKey="userId" />
          </Table.Column>
        </Table>
      </Content>
    </Container>
  );
};

export default BankPayoutAccounts;
