export default class Endpoints {
  static BASE_URL =
    process.env.REACT_APP_ENVIRONMENT === "DEV"
      ? `${process.env.REACT_APP_BASE_URL_DEV}/om/`
      : `${process.env.REACT_APP_BASE_URL_PROD}/om/`;

  static REGISTER_EMAIL = `${Endpoints.BASE_URL}admin-auth/register-email`;

  static VERIFY_EMAIL = `${Endpoints.BASE_URL}admin-auth/sign-in/verify-email`;

  static EMAIL_LOGIN = `${Endpoints.BASE_URL}admin-auth/sign-in/email-login`;

  static FORGOT_PASSWORD = `${Endpoints.BASE_URL}admin-auth/forgot-password`;

  static CHANGE_PASSWORD = `${Endpoints.BASE_URL}admin-auth/change-password`;

  static VERIFY_TOKEN = `${Endpoints.BASE_URL}admin-auth/verify-token`;

  static LOGOUT_USER = `${Endpoints.BASE_URL}admin-auth/logout`;

  static DASHBOARD_POST_ORDER = `${Endpoints.BASE_URL}admin/dashboard/post-order`;

  static POST_ORDER = `${Endpoints.BASE_URL}admin/post-order/list`;

  static CONTENT_ORDER_ACTION = `${Endpoints.BASE_URL}admin/content-order/action`;

  static POST_ORDER_ACTION = `${Endpoints.BASE_URL}admin/post-order/action`;

  static PAYPAL_INVOICE_ACTION = `${Endpoints.BASE_URL}admin/paypal-invoice/`;

  static CONTENT_ORDER = `${Endpoints.BASE_URL}admin/content-order/list`;

  static DASHBOARD_CONTENT_ORDER = `${Endpoints.BASE_URL}admin/dashboard/content-order`;

  static USER_FORGET_PASSWORD = `${Endpoints.BASE_URL}admin/user/forgot-password`;

  static USERS_LIST = `${Endpoints.BASE_URL}admin/user/list`;

  static USERS_UPDATE = `${Endpoints.BASE_URL}admin/user/update`;

  static USER_PREF_LIST = `${Endpoints.BASE_URL}admin/user/inventory-prefs/list`;

  static USER_WEBSITE_LIST = `${Endpoints.BASE_URL}admin/user/website/list`;

  static WEBSITES_LIST = `${Endpoints.BASE_URL}admin/website/list`;

  static WEBSITES_FEATURED = `${Endpoints.BASE_URL}admin/website/action/featured`;

  static WEBSITES_UPDATE = `${Endpoints.BASE_URL}admin/website/action`;

  static VERIFY_DOMAIN = `${Endpoints.BASE_URL}admin/domain-verification/add`;

  static RECENT_UPDATES = `${Endpoints.BASE_URL}admin/website/update/modified-on`;

  static CACHE_RESET = `${Endpoints.BASE_URL}admin/cache-reload`;

  static CONTACT_US_LIST = `${Endpoints.BASE_URL}admin/contact-us-list`;

  static CONTACT_US_UPDATE_ALL = `${Endpoints.BASE_URL}admin/contact-us/update`;

  static CONTACT_US = `${Endpoints.BASE_URL}admin/contact-us/`;

  static PAYPAL_PAYOUT_LIST = `${Endpoints.BASE_URL}admin/paypal/payout/list`;

  static PAYPAL_PAYOUT_INIT = `${Endpoints.BASE_URL}admin/paypal/payout/initiate`;

  static PAYPAL_PAYOUT_PENDING = `${Endpoints.BASE_URL}admin/paypal/payout/pending`;

  static PAYPAL_SETTLEMENT = `${Endpoints.BASE_URL}admin/paypal/payout/settlement`;

  static BANK_PAYMENT_ACCOUNTS = `${Endpoints.BASE_URL}admin/bank/payout/payment-account/list`;

  static BANK_PAYOUT_LIST = `${Endpoints.BASE_URL}admin/bank/payout/list`;

  static BANK_PENDING_LIST = `${Endpoints.BASE_URL}admin/bank/payout/pending`;

  static PAYOUT_LISTING = `${Endpoints.BASE_URL}admin/payout/listing`;

  static BANK_SETTLEMENT = `${Endpoints.BASE_URL}admin/bank/payout/settlement`;

  static RECENT_PAYOUT = `${Endpoints.BASE_URL}admin/payout/recent`;

  static UPLOAD_IMAGE = `${Endpoints.BASE_URL}admin/upload/images`;

  static UPLOAD_CONFIG = `${Endpoints.BASE_URL}admin/update/config`;

  static ORDER_CONTENT_UPLOAD = `${Endpoints.BASE_URL}admin/content-order/upload`;

  static PAYMENT_ORDERS_LIST = `${Endpoints.BASE_URL}admin/payment/listing`;

  static PAYPAL_INVOICE_LIST = `${Endpoints.BASE_URL}admin/paypal-invoice/list`;

  static USER_DETAILS = `${Endpoints.BASE_URL}admin-user`;

  static WEBSITE_STATUS_UPDATE = `${Endpoints.BASE_URL}admin/website/action/status`;

  static WEBSITE_PENDING_LIST = `${Endpoints.BASE_URL}admin/website/list/pending`;

  static POST_ORDER_DETAILS = `${Endpoints.BASE_URL}admin/post-order/details`;

  static CONTENT_ORDER_DETAILS = `${Endpoints.BASE_URL}admin/content-order/details`;
}
