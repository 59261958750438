import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Content,
  Header,
  Message,
  Table,
  toaster,
} from "rsuite";
import { postPaypalPayoutInitialize } from "../../services/PaypalServices";
import exportFromJSON from "export-from-json";
import { AmountCell } from "../../components/CustomCells";
import dayjs from "dayjs";

const PaypalPayoutInitiate = () => {
  const [data, setData] = useState([]);
  const [disablePayoutButton, setDisablePayoutButton] = useState(false);
  const initiatePayout = async () => {
    const response = await postPaypalPayoutInitialize();
    setDisablePayoutButton(true);
    console.log(response);
    if (response.status === 200) {
      setData(response.data);
    }
    // else {
    //   toaster.push(
    //     <Message showIcon type="error" header={response.response.status}>
    //       {response.response.data.message}
    //     </Message>,
    //     {
    //       placement: "topEnd",
    //       duration: 2000,
    //     }
    //   );
    // }
  };
  const ExportToExcel = () => {
    const DateTime = dayjs().format("DD_MM_YYYY_HH:mm");
    const fileName = `PaypalInitiateData_${DateTime}`;
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <Container>
      <Header>
        <h2 className="ms-2">Paypal Payout Initiate</h2>
      </Header>
      <Content>
        <div className="m-3">
          <Button appearance="ghost" onClick={ExportToExcel}>
            Download Data (csv)
          </Button>
          <Button
            disabled={disablePayoutButton}
            appearance="ghost"
            className="ms-3"
            onClick={initiatePayout}
          >
            Initiate Payout
          </Button>
        </div>
        <Table height={window.innerHeight - 200} data={data}>
          <Table.Column width={150}>
            <Table.HeaderCell>User Id</Table.HeaderCell>
            <Table.Cell dataKey="userId" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Order Id</Table.HeaderCell>
            <Table.Cell dataKey="orderId" />
          </Table.Column>
          <Table.Column width={350}>
            <Table.HeaderCell>Paypal Address</Table.HeaderCell>
            <Table.Cell dataKey="paypalAddress" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.Cell dataKey="amount" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Payout Amount</Table.HeaderCell>
            <Table.Cell dataKey="payoutAmount" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.Cell dataKey="status" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Paypal Txn. ID</Table.HeaderCell>
            <Table.Cell dataKey="paypalTransactionId" />
          </Table.Column>
        </Table>
      </Content>
    </Container>
  );
};

export default PaypalPayoutInitiate;
